import React, { useState, useEffect, useRef, startTransition } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ToolbarButton from "../utils/libraries/ToolbarButton";
import MenuDispensor from "../utils/libraries/MenuDispensor";
import { useParams, useNavigate } from "react-router-dom";
import { setGlobalState, useGlobalState } from "../utils/state";
import { Howler, Howl } from "howler";
import "../utils/css/mainPage.css";
import { BaseAPI, CityDB, ApplicationDB_IOC, assetsLocation, packageApp, ApplicationDB } from "../assets/assetsLocation";
import { setTourState } from "../hooks/animations";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker";
import { CSSTransition } from "react-transition-group";
import { initialize } from "react-ga";
import um1 from "../assets/iocvideo1.mp4"
import { resetLights } from "../utils/libraries/LightUtils";

const ToolbarMenu_IOC = (props) => {
    const MainMenuIsButtons = false;
    const location = useLocation();
    const buttonRef = useRef(null);
    const { toPress, loadID } = useParams();
    const [extraData, setExtraData] = useState([
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
    ]);
    const [selectedButton, setSelectedButton] = useGlobalState("selectedButton");
    const [showCardContainer, setShowCardContainer] = useState(false);
    const [sectionData, setSectionData] = useState([]);
    const [dimBg, setDimBg] = useGlobalState("dimBg");

    const [ui_Element, setUI_Element] = useState(null);

    const [showTour, setShowTour] = useGlobalState("showTour");
    const [buttonType, setButtonType] = useState("");

    const [showUC, setShowUC] = useGlobalState("showUC");
    const [solutionsId, setSolutionsId] = useGlobalState("solutionsId");
    const [useCase, setUseCase] = useGlobalState("useCase");
    const [playVideo, setPlayVideo] = useState(false);

    const [isResetClick, setIsResetClick] = useState(false);
    var guidedTourOpen = false;
    const [IsGuidedTourOpen, setGuidedTourOpen] = useState(false);
    const [useCaseMapping, setUseCaseMapping] = useState(false);
    const [HoverId, setHoverId] = useGlobalState("HoverId");
    const [HoverLabel, setHoverLabel] = useGlobalState("HoverLabel");
    const [clientXPosition1, setClientXPosition1] = useGlobalState("clientXPosition1");
    const [clientYPosition1, setClientYPosition1] = useGlobalState("clientYPosition1");
    const [isTourOpen, setIsTourOpen] = useGlobalState("IsTourOpen");
    const [isHomeButtonClick, setIsHomeButtonClick] =
        useGlobalState("IsHomeButtonClick");
    const [playAndPause, setPlayAndPause] = useGlobalState("playAndPause");
    const [currentSound, setCurrentSound] = useState(null);
    // const gaEventTracker = useAnalyticsEventTracker("ToolBarMenu");
    const [anchorEl, setAnchorEl] = useState(null);
    const [scene, setScene] = useGlobalState("scene");
    const [UcGuidedTour, setUcGuidedTour] = useGlobalState("UcGuidedTour");
    let alignItems = true;

    const open = anchorEl;
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setSelectedButton("selectedButton")
    };
    const handleMenuItemClick = () => {
        setAnchorEl(null);
        // setSelectedButton("selectedButton")
    };
    const links = new Map([
        ["outcomes", "btnOutcomes"],
        ["blocks", "btnBuildingBlocks"],
        ["partner", "btnPartnerSolutions"],
        ["solutions", "btnDellSolutions"],
        ["use_case_stories", "btnUseCasesEnabled"]
    ]);

    // Set screen to initial state
    const resetScreen = () => {
				resetLights(scene);
        setPlayVideo(false);
        setGlobalState("IsBackgroundBlur", false);
        // setGlobalState("IsButtonContainer", true);
        setTourState(false);
        setSelectedButton(null);
        // setGuidedTourOpen(false);
        setGlobalState("IsGuidedTourOpen", false);
        setShowCardContainer(false);
        // setUseCaseMapping(false);
        setUI_Element(null);
        setGlobalState("useCase", 0);
        setGlobalState("solutionsId", -1);
        // setGlobalState("IsButtonContainer", true);
        // setGlobalState("mapped_use_case", null);
        // setGlobalState("HoverId",0);
        setGlobalState("HoverUseCaseId", 0);
        setShowUC(false);
        setGlobalState("showDC", false);
        setGlobalState("showUC", false);
        // props.resetCamera();

        Howler.stop();
    };

    useEffect(() => {
        if (selectedButton == "tour" && isTourOpen == false) {
            setSelectedButton(null);
        }
    }, [isTourOpen]);

    const handleTourButtonClick = (buttonId) => {
        if (!playAndPause) {
            setGlobalState("playAndPause", true)
        }
        setGlobalState("ApplicationDB", ApplicationDB_IOC)
        if (selectedButton === buttonId) {
            // if same button clicked again, reset screen
            setGlobalState("showDC", false);
            if (buttonId !== "button3") {
                resetScreen();
                return;
            }
        }

        setSelectedButton(buttonId);
        //  setUI_Element('')
        setShowCardContainer(false)
        // if (buttonId === "watchvideo") {     // Watch Video
        //     setShowCardContainer(false);
        //     if (isTourRunning.current) {
        //         isTourRunning.current = false;
        //         stopTour();
        //     }


        //     setPlayVideo(true);
        //     setDimBg(true);
        //     return;
        // }
    };

    useEffect(() => {
        if (currentSound == null) return;
        if (!playAndPause) {
            currentSound.pause();
        } else {
            currentSound.play();
        }
    }, [playAndPause]);

    useEffect(() => {
        if (toPress != null) {
            if (toPress === "tour") {
                handleTourButtonClick(toPress);
            } else {
                handleButtonClick(links.get(toPress));
            }
        }
    }, [toPress]);

    const handlePlayStory = () => {
        // ga
        if (HoverId > 0) {
            setGlobalState("HoverUseCaseId", HoverId);
        }
        handleUseCaseButtonClick("btnUseCasesEnabled");
        setGlobalState("IsButtonContainer", false);
    }
    useEffect(() => {
        if (isHomeButtonClick) {
            // setUI_Element("");
            setGlobalState("useCase", 0);
            setGlobalState("HoverUseCaseId", 0);
            setSelectedButton(null);
            // setGlobalState("IsButtonContainer", false);
        }
    }, [isHomeButtonClick]);

    useEffect(() => {
        if (playVideo) {
            if (playAndPause) {
                document.getElementById("um-video").play()
            } else {
                document.getElementById("um-video").pause()
            }
        }
    }, [playAndPause]);

    const handleUseCaseButtonClick = async (buttonId) => {
        setGlobalState("IsHomeButtonClick", false);
        setGlobalState("ApplicationDB", ApplicationDB_IOC);
        if (isTourOpen) {
            // document.getElementById("close-btn").click();
            props.resetCamera();
        }
        Howler.stop();
        setUI_Element("");
        if (selectedButton === buttonId) {
            // if same button clicked again, reset screen
            resetScreen();
            return;
        }
        setSelectedButton(buttonId);
        try {
            const baseAPIUrl = `${BaseAPI}use_case_list_segment/?db=${ApplicationDB_IOC}&startID=100`;
            const address = !packageApp ? baseAPIUrl : `../../${ApplicationDB}/use_case_list_segment/100.json`; //address for fetching sectiondata
            // const address = baseAPIUrl + id;//address for fetching sectiondata
            const response = await fetch(address); //fetch section data files for specific config id
            const data = await response.json();

            if (buttonId === "btnUseCasesEnabled") {
                setButtonType("Use_case");
                setGlobalState("IsButtonContainer", true);
                setUI_Element("popuptoolbar");
            } else {
                setUI_Element("cards");
            }
            setSectionData(data.use_case_list);

            setShowCardContainer(true);
            if (buttonId === "btnUseCasesEnabled") {
                setShowUC(true);
                setGlobalState("showUC", true);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        return;
    };

    async function fetchData() {
        for (var id = 0; id < 9; id++) {
            var baseAPIUrl;
            var address;
            if (id === 8) {
                baseAPIUrl = `${BaseAPI}use_case_list_segment/`;
                address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB_IOC}&startID=100` : `../../${ApplicationDB}/use_case_list_segment/100.json`; //address for fetching sectiondata
            } else if (id === 7) {
                baseAPIUrl = `${BaseAPI}solutions`;
                address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB_IOC}&type=U` : `../../${ApplicationDB}/solutions/U.json`; //address for fetching sectiondata
            } else if (id === 6) {
                baseAPIUrl = `${BaseAPI}solutions`;
                address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB_IOC}&type=V` : `../../${ApplicationDB}/solutions/V.json`; //address for fetching sectiondata
            } else {
                baseAPIUrl = `${BaseAPI}section/`;
                address = !packageApp ? `${baseAPIUrl + (100 + id)}?db=${ApplicationDB_IOC}` : `../../${ApplicationDB}/section/${(100 + id)}.json`; //address for fetching sectiondata
            }
            // CHANGES HERE
            try {
                // console.log("API CALLED");
                const response = await fetch(address); //fetch section data files for specific config id
                const data = await response.json();
                extraData[id - 1].push(data);
                if (id === 8) {
                    setGlobalState("use_case_list", data);
                }
            } catch (error) {
                // console.error("Error fetching data:", error);
            }
        }
    }


    async function fetchAudio() {
        const baseAPIUrl = `${BaseAPI}use_case_list_segment/`;
        const address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB_IOC}&startID=100` : `../../${ApplicationDB}/use_case_list_segment/100.json`;
        const response = await fetch(address);
        const data = await response.json();

        let Vosound;
        const audioClips = new Map();
        const audio_Paths = new Map();

        data.use_case_list.forEach((uc) => {
            const id = uc.use_case_id;
            const src_url = !packageApp ?
                `${assetsLocation}${ApplicationDB_IOC}/audio/uc` + String(id) + "/" :
                `../../${ApplicationDB_IOC}/audio/uc` + String(id) + "/";
            const path = src_url + "10.mp3";
            try {
                Vosound = new Howl({
                    src: path,
                    html5: true,
                    onpause: false,
                    preload: true,
                });
                audioClips.set(id, Vosound);
                audio_Paths.set(id, path);
            } catch {
            }
        });
        setGlobalState("audioVO1", audioClips);
        setGlobalState("audioPathVO1", audio_Paths);

        const audioClips2 = new Map();
        const audio_Paths2 = new Map();
        data.use_case_list.forEach((uc) => {
          const id = uc.use_case_id;
          const src_url = !packageApp ?
            `${assetsLocation}${ApplicationDB}/audio/uc` + String(id) + "/" : `../../${ApplicationDB}/audio/uc${id}/`;
          const path = src_url + "11.mp3";
          try {
            Vosound = new Howl({
              src: path,
              html5: true,
              onpause: false,
              preload: true,
            });
            audioClips2.set(id, Vosound);
            audio_Paths2.set(id, path);
          } catch {
          }
        });
        setGlobalState("audioVO2", audioClips2);
        setGlobalState("audioPathVO2", audio_Paths2);
    }

    useEffect(async () => {
        await fetchData();
        await fetchAudio();
        document.getElementById("btnUseCasesEnabled").click();
        await new Promise(resolve => setTimeout(resolve, 10));
        document.getElementById("reset").click();
        console.log(ApplicationDB_IOC);

    }, []);

    const handleButtonClick = async (buttonId) => {
        // gaEventTracker(buttonId);

        setSelectedButton(buttonId);
        setTourState(false);
        if (!playAndPause) {
            setGlobalState("playAndPause", true);
        }
        setGlobalState("IsAutoPlay", false);
        setGlobalState("IsHomeButtonClick", false);
        setGlobalState("ApplicationDB", ApplicationDB_IOC);
        if (isTourOpen) {
            setGlobalState("UCTourId", 0);
            // document.getElementById("close-btn").click();
            props.resetCamera();
        }
        Howler.stop();
        setUI_Element("");

        setShowCardContainer(true);

        return;
    };

    const handleResetButtonClick = () => {
        // setUseCaseMapping(false);
        setPlayVideo(false);
        setGlobalState("IsBackgroundBlur", false);
        if (MainMenuIsButtons) {
            setIsResetClick(true)
        }
        setTimeout(() => {
            setIsResetClick(false)
        }, 1000);
        setGlobalState("IsGuidedTourOpen", false);
        setTourState(false);
        setSelectedButton(null);
        setShowCardContainer(false);
        setGlobalState("solutionsId", -1);
        setGlobalState("showDC", false);
        setGlobalState("showUC", false);
        setGlobalState("IsTourOpen", false);
        setGlobalState("UCTourId", 0);
        setGlobalState("IsHomeButtonClick", true);
        setGlobalState("HoverId", 0);
        setGlobalState("HoverUseCaseId", 0);
        setGlobalState("IsButtonContainer", true);
        setGuidedTourOpen(false);
        Howler.stop();
        // document.getElementById("close-btn").click();
				resetScreen();
        props.resetCamera();
    };

    useEffect(() => {
        if (solutionsId != -1)
            handleSolutionsButtonClick("btnDellSolutions", solutionsId);
    }, [solutionsId]);

    const handleSolutionsButtonClick = (buttonId, HoverId) => {
        if (selectedButton === buttonId) {
            // if same button clicked again, reset screen
            resetScreen();
            return;
        }
        setSelectedButton(buttonId);
        setGlobalState("IsButtonContainer", false);
        setShowCardContainer(true);
        setGlobalState("solutionsId", HoverId);
        setUseCaseMapping(false);
        handleButtonClick(buttonId);
        setGlobalState("useCase", 0);
        setGlobalState("HoverUseCaseId", 0);
        setGlobalState("IsTourOpen", false);
        setSectionData(extraData[6][0].Solutions);
        console.log(extraData[6][0].Solutions);
        setButtonType("P");
        setGlobalState("showDC", false);
        setGlobalState("showUC", false);
        setUI_Element("popuptoolbar");
    };

    const numToButtonId = new Map([
        ["1", "btnOutcomes"],
        ["2", "btnUseCasesEnabled0"],
        ["3", "btnUseCasesEnabled1"],
        ["4", "btnUseCasesEnabled2"],
        ["5", "btnUseCasesEnabled3"],
        ["6", "btnBuildingBlocks"],
        ["7", "btnDellSolutions1"],
        ["8", "btnDellSolutions2"],
        ["9", "btnDellSolutions3"],
        ["10", "btnDellSolutions4"],
        ["11", "btnPartnerSolutions1"],
        ["12", "btnPartnerSolutions2"]
        // ["13", "watchvideo"]
    ]);


    var step = 0;
    useEffect(() => {
        console.log(UcGuidedTour, guidedTourOpen, IsGuidedTourOpen);
        if (UcGuidedTour > 0 && IsGuidedTourOpen == true) {
            setTimeout(() => {
                step = UcGuidedTour;
                console.log(step);
                playGuidedTour();
            }, 300);
        }
    }, [UcGuidedTour, guidedTourOpen]);

    const playGuidedTour = () => {
        console.log(guidedTourOpen, step, IsGuidedTourOpen);
        if (step == 13) {
            setSelectedButton(null);
            setGlobalState("showDC", false);
            setGlobalState("IsBackgroundBlur", false);
        }
        if (step == 6) {
            props.resetCamera();
            setSelectedButton("btnBuildingBlocks");
            document.getElementById(numToButtonId.get(`${step}`)).click();
        }
        if (numToButtonId.get(`${step}`) && numToButtonId.get(`${step}`).includes("btnUseCasesEnabled")) {
            const button = numToButtonId.get(`${step}`);
            const id = button.charAt(button.length - 1);
            console.log(step, id);
            if (id != "0") {
                const idd = Number(id);
                document.getElementById("btnUseCasesEnabled").click();
                setTimeout(() => {
                    try {
                        document.getElementsByClassName("MuiButtonBase-root")[idd - 1].click();
                        setGlobalState("IsBackgroundBlur", true);
                    } catch (error) {
                        document.getElementById("btnUseCasesEnabled").click();
                        console.log(step, idd);
                        setTimeout(() => {
                            document.getElementsByClassName("MuiButtonBase-root")[idd - 1].click();
                            setGlobalState("IsBackgroundBlur", true);
                        }, 400);
                    }
                }, 1000);
                // if(id == "3") props.resetCamera();
                return;
            }
        }

        const sound = new Howl({
            src: !packageApp ? `${assetsLocation}${ApplicationDB_IOC}/audio/ioc_gt_testv/${step}.mp3` : `../../${ApplicationDB_IOC}/audio/ioc_gt_testv/${step}.mp3`,
            html5: true,
            loop: false
        });
        setCurrentSound(sound);
        sound.play();


        // sound.volume(1);
        sound.on("end", async function () {
            // if (step == 7) console.log("passed through here via step 7");
            // if(!guidedTourOpen) {
            // 	Howler.stop();
            // 	return;
            // }
            setCurrentSound(null);
            if (step == 13) {
                // await document.getElementById("btnPartnerSolutions").click();
                // resetScreen();
                // handleClose();
                setGlobalState("showDC", false);
                setSelectedButton("btnGuidedTour");
                step = 0;
                playGuidedTour();
                return;
            }
            else if (step == 12) {
                setAnchorEl(null);
                playGuidedTour(++step);
            }
            else if (step == 12) {
                setAnchorEl(null);
                ++step;
                playGuidedTour();
            }
            else if (numToButtonId.get(`${step + 1}`).includes("btnDellSolutions")) {
                const button = numToButtonId.get(`${step + 1}`);
                const id = button.charAt(button.length - 1);
                // if (!document.getElementById("btnDellSolutions"))
                document.getElementById("btnDellSolutions").click();
                // document.getElementById("btnDellSolutions").click();
                const idd = Number(id);

                setTimeout(() => {
                    try {
                        console.log(idd);
                        document.getElementsByClassName("MuiButtonBase-root")[idd - 1].click();
                    } catch (error) {
                        // If there's an error, handle it here
                        // You can leave this block blank for now and add your handling code later
                        document.getElementById("btnDellSolutions").click();

                        setTimeout(() => {
                            document.getElementsByClassName("MuiButtonBase-root")[idd - 1].click();
                        }, 500);
                    }
                }, 500);

                console.log(id, "20" + id);
                // setGlobalState("solutionsId", "20" + id);
                // setSolutionsId("20" + id);
                ++step;
                playGuidedTour();
            }
            else if (numToButtonId.get(`${step + 1}`).includes("btnUseCasesEnabled")) {
                const button = numToButtonId.get(`${step + 1}`);
                const id = button.charAt(button.length - 1);
                console.log(step, id);
                if (id == "0") {
                    document.getElementById("btnUseCasesEnabled").click();
                    ++step;
                    playGuidedTour();
                }
                else {
                    const idd = Number(id);
                    document.getElementsByClassName("MuiButtonBase-root")[0].click();
                    // setTimeout(() => {
                    // 		try {
                    // 				document.getElementsByClassName("MuiButtonBase-root")[idd - 1].click();
                    // 		} catch (error) {
                    // 				document.getElementById("btnUseCasesEnabled").click();

                    // 				setTimeout(() => {
                    // 						document.getElementsByClassName("MuiButtonBase-root")[idd - 1].click();
                    // 				}, 400);
                    // 		}
                    // }, 1000);
                    console.log("SET");
                    setGlobalState("UcGuidedTour", 3);
                }
            }
            else if (numToButtonId.get(`${step + 1}`).includes("btnPartnerSolutions")) {
                const button = numToButtonId.get(`${step + 1}`);
                const id = button.charAt(button.length - 1);
                await document.getElementById("btnPartnerSolutions").click();
                const idd = Number(id);

                setTimeout(() => {
                    try {
                        document.getElementsByClassName("MuiButtonBase-root")[idd - 1].click();
                    } catch (error) {
                        // If there's an error, handle it here
                        // You can leave this block blank for now and add your handling code later
                        document.getElementById("btnPartnerSolutions").click();

                        setTimeout(() => {
                            document.getElementsByClassName("MuiButtonBase-root")[idd - 1].click();
                        }, 400);
                    }
                }, 2000);
                console.log(id, "30" + id);

                ++step;
                playGuidedTour();
            }
            else {
                // resetScreen();
                setAnchorEl(null);
                if (step !== 12) { document.getElementById(numToButtonId.get(`${step + 1}`)).click(); }
                if (step == 6) { setSelectedButton("btnBuildingBlocks"); }
                ++step;
                playGuidedTour();
            }
        });
    };


    return (
        <div>
            <CSSTransition
                in={HoverId > 0}
                timeout={300} // Duration of the animation in milliseconds
                classNames="animationHover" // Your CSS class for animations
                unmountOnExit
                mountOnEnter
            >
                <div style={{ top: clientYPosition1, left: clientXPosition1 }} className="hot-spot-subMenu">
                    <div>
                        <div className="hover-label-text">{HoverLabel}</div>
                        <hr style={{ marginTop: "5%" }} className="card-divider"></hr>
                    </div>
                    <div className="button-group" >
                        {/* {(isTourOpen || useCase !== 0) ? "" :
                            (scene.activeCamera.name.includes("security") == false && scene.activeCamera.name.includes("cr-camera") == false) ?
                                <div className="zoom-in" onClick={() => setGlobalState("currentZoomedSection", HoverId)}>Zoom-in</div>
                                :
                                <div className="zoom-in" onClick={() => props.resetCamera()}>Zoom-out</div>
                        } */}
                        <div className="learn-more" onClick={() => handlePlayStory()}>{useCase !== 0 ? "End Story" : "Learn More"}</div>
                    </div>
                </div>
            </CSSTransition>

            <div id="dummy" style={{ display: 'flex' }}>

                <div className={`${MainMenuIsButtons ? "toolbar reset-toolbar" : "plain-reset-toolbar"} `} >
                    <ToolbarButton
                        forwardRef={buttonRef}
                        id="reset"
                        buttonId="reset"
                        selectedButton={selectedButton}
                        active={"reset" === selectedButton}
                        buttonName="Reset the Experience"
                        handleButtonClick={handleResetButtonClick}
                        handleMenuClick={() => { }}
                        MainMenuIsButtons={MainMenuIsButtons}
                    >

                        <svg width="4vh" height="4vh" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_bd_38_67)">
                                <rect x="6" y="3" width="52" height="52" rx="26" fill="#D8D8D8" />
                            </g>
                            <path d="M31.6279 40C28.3821 40 25.6323 38.8867 23.3787 36.6601C21.1262 34.4324 20 31.714 20 28.5049C20 25.2959 21.1262 22.5764 23.3787 20.3465C25.6323 18.1155 28.3821 17 31.6279 17C33.6024 17 35.4507 17.4638 37.1728 18.3915C38.8959 19.3192 40.2846 20.6143 41.3389 22.2769V17H43V25.5921H34.3123V23.9493H40.4585C39.5936 22.3119 38.3765 21.0179 36.8073 20.0672C35.2403 19.1176 33.5138 18.6429 31.6279 18.6429C28.8594 18.6429 26.5061 19.6012 24.5681 21.5179C22.6301 23.4345 21.6611 25.7619 21.6611 28.5C21.6611 31.2381 22.6301 33.5655 24.5681 35.4821C26.5061 37.3988 28.8594 38.3571 31.6279 38.3571C33.7597 38.3571 35.6838 37.7548 37.4003 36.55C39.1168 35.3452 40.3211 33.7571 41.0133 31.7857H42.7757C42.0437 34.2456 40.6523 36.2296 38.6013 37.7378C36.5504 39.2459 34.2259 40 31.6279 40Z" fill="black" />
                            <defs>
                                <filter id="filter0_bd_38_67" x="0" y="-3" width="64" height="67" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="3" />
                                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_38_67" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dy="3" />
                                    <feGaussianBlur stdDeviation="3" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                                    <feBlend mode="normal" in2="effect1_backgroundBlur_38_67" result="effect2_dropShadow_38_67" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_38_67" result="shape" />
                                </filter>
                            </defs>
                        </svg>

                        Reset
                    </ToolbarButton>
                </div>


                <div
                    // style={{ justifyContent: MainMenuIsButtons ? "center" : "end" }}
                    className={`${MainMenuIsButtons ? "toolbar" : "plain-toolbar"} `}
                >
                    <ToolbarButton  // Guided Tour button
                        buttonId="btnGuidedTour"
                        selectedButton={selectedButton}
                        active={"btnGuidedTour" === selectedButton}
                        buttonName="Guided Tour"
                        handleButtonClick={async (buttonId, buttonName) => {
                            if (selectedButton === buttonId) {
                                // if same button clicked again, reset screen
                                resetScreen();
                                return;
                            }
                            guidedTourOpen = true;
                            setGuidedTourOpen(true);
                            handleButtonClick(buttonId);
                            startTransition(() => {
                                setGlobalState("IsGuidedTourOpen", true);
                            });
                            setGlobalState("IsBackgroundBlur", false);
                            setGlobalState("useCase", 0);
                            setGlobalState("HoverUseCaseId", 0);
                            step = 0;
                            playGuidedTour();
                        }}
                        handleMenuClick={() => { }}
                        MainMenuIsButtons={MainMenuIsButtons}
                    >
                        Guided Tour
                    </ToolbarButton>
                    {MainMenuIsButtons ? "" : <div className='plain-divider'></div>}
                    <ToolbarButton // Outcomes
                        buttonId="btnOutcomes" //1
                        selectedButton={selectedButton}
                        active={"btnOutcomes" === selectedButton}
                        buttonName="Outcomes"
                        handleButtonClick={async (buttonId, buttonName) => {
                            if (selectedButton === buttonId) {
                                // if same button clicked again, reset screen
                                resetScreen();
                                return;
                            }
                            setUseCaseMapping(false);
                            handleButtonClick(buttonId);
                            setGlobalState("IsBackgroundBlur", true);
                            setGlobalState("useCase", 0);
                            setGlobalState("HoverUseCaseId", 0);
                            setGlobalState("IsTourOpen", false);

                            if (extraData[1][0] == null) {
                                const baseAPIUrl = `${BaseAPI}section/`;
                                const address = !packageApp ? `${baseAPIUrl + "102"}?db=${ApplicationDB_IOC}` : `../../${ApplicationDB_IOC}/section/102.json`;
                                try {
                                    const response = await fetch(address);
                                    const data = await response.json();
                                    extraData[1].push(data);
                                } catch (error) {
                                    console.log("Error fetching data:", error);
                                }
                            }

                            setSectionData(extraData[1][0].SectionData);

                            setUI_Element("cards");
                        }}
                        handleMenuClick={() => { }}
                        MainMenuIsButtons={MainMenuIsButtons}
                    >
                        Outcomes
                    </ToolbarButton>

                    {MainMenuIsButtons ? "" : <div className='plain-divider'></div>}
                    <ToolbarButton // Use Case Story Button
                        buttonId="btnUseCasesEnabled" //8
                        selectedButton={selectedButton}
                        active={"btnUseCasesEnabled" === selectedButton}
                        buttonName="Use Cases Enabled"
                        handleButtonClick={async (buttonId, buttonName) => {
                            fetchAudio();
                            if (selectedButton === buttonId) {
                                // if same button clicked again, reset screen
                                resetScreen();
                                // return;
                            }
                            setShowCardContainer(true);
                            setUseCaseMapping(false);
                            handleButtonClick(buttonId);
                            setGlobalState("IsTourOpen", false);
                            setGlobalState("IsBackgroundBlur", false);
                            // setGlobalState("currentZoomedInSection", false);

                            if (extraData[7][0] == null) {
                                const baseAPIUrl = `${BaseAPI}use_case_list_segment`;
                                const address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB_IOC}&startID=100` : `../../${ApplicationDB_IOC}/use_case_list_segment/100.json`;
                                try {
                                    const response = await fetch(address);
                                    const data = await response.json();
                                    extraData[7].push(data);
                                } catch (error) {
                                    console.error("Error fetching data:", error);
                                }
                            }
                            setSectionData(extraData[7][0].use_case_list);
                            setUI_Element("cascadingioc");
                            setButtonType("Use_case");
                            setGlobalState("HoverUseCaseId", 0);
                            setGlobalState("IsButtonContainer", true);
                            setGlobalState("playUCDirectly", false);
                        }
                        }
                        handleMenuClick={handleClick}
                        MainMenuIsButtons={MainMenuIsButtons}
                    >
                        Use Cases


                    </ToolbarButton >

                    {MainMenuIsButtons ? "" : <div className='plain-divider'></div>}
                    < ToolbarButton // DVS button
                        buttonId="btnBuildingBlocks" //4
                        active={"btnBuildingBlocks" === selectedButton}
                        selectedButton={selectedButton}
                        buttonName="Guiding Principles"
                        handleButtonClick={async (buttonId, buttonName) => {
                            if (selectedButton === buttonId) {
                                // if same button clicked again, reset screen
                                resetScreen();
                                return;
                            }
                            setUseCaseMapping(false);
                            handleButtonClick(buttonId);
                            setSelectedButton(buttonId);
                            setGlobalState("IsBackgroundBlur", true);
                            setGlobalState("useCase", 0);
                            setGlobalState("HoverUseCaseId", 0);
                            setGlobalState("IsTourOpen", false);

                            if (extraData[0][0] == null) {
                                const baseAPIUrl = `${BaseAPI}section/`;
                                const address = !packageApp ? `${baseAPIUrl + "101"}?db=${ApplicationDB_IOC}` : `../../${ApplicationDB_IOC}/section/101.json`; //address for fetching sectiondata
                                // CHANGES HERE
                                // console.log(address);
                                try {
                                    // console.log("API CALLED");
                                    const response = await fetch(address); //fetch section data files for specific config id
                                    const data = await response.json();
                                    extraData[0].push(data);
                                } catch (error) {
                                    // console.error("Error fetching data:", error);
                                }
                            }

                            setSectionData(extraData[0][0].SectionData);

                            setUI_Element("");
                            setUI_Element("cards");
                        }}
                        handleMenuClick={() => { }}
                        MainMenuIsButtons={MainMenuIsButtons}
                    >
                        Building Blocks
                    </ToolbarButton >

                    {MainMenuIsButtons ? "" : <div className='plain-divider'></div>}
                    < ToolbarButton
                        buttonId="btnDellSolutions"
                        active={"btnDellSolutions" === selectedButton}
                        selectedButton={selectedButton}
                        buttonName="Dell Solutions"
                        handleButtonClick={async (buttonId, buttonName) => {
                            if (!playAndPause) {
                                setGlobalState("playAndPause", true)
                            }
                            setGlobalState("ApplicationDB", ApplicationDB_IOC)
                            if (selectedButton === buttonId) {
                                // if same button clicked again, reset screen
                                setGlobalState("showDC", false);
                                resetScreen();
                                return;
                            }

                            setSelectedButton(buttonId);
                            setGlobalState("IsButtonContainer", true);
                            //  setUI_Element('')
                            setShowCardContainer(false);
                            try {
                                const baseAPIUrl = `${BaseAPI}section/`;
                                // Fetch data from API
                                const id = 7;
                                let address = !packageApp ? `${baseAPIUrl + id}?db=${ApplicationDB_IOC}&type=V` : `../../${ApplicationDB_IOC}/solutions/V.json`;//address for fetching sectiondata
                                if (buttonId === "btnDellSolutions") {
                                    address = !packageApp ? `${BaseAPI}solutions?db=${ApplicationDB_IOC}&type=U` : `../../${ApplicationDB_IOC}/solutions/U.json`;
                                }
                                if (extraData[id - 1].length == 0) {
                                    // console.log("API CALLED");
                                    const response = await fetch(address); //fetch section data files for specific config id
                                    const data = await response.json();
                                    extraData[id - 1].push(data);
                                    // console.log(extraData[id-1].length);
                                }
                                const data = extraData[id - 1][0];
                                // Assign UI Element to display data

                                setButtonType("D");
                                setUI_Element("popuptoolbar");
                                setSectionData(data.Solutions);
                                setShowCardContainer(true);
                                setDimBg(true);
                            }
                            catch (error) {
                                console.error('Error fetching data:', error);
                            }
                            return;
                        }}
                        handleMenuClick={handleClick}
                        MainMenuIsButtons={MainMenuIsButtons}
                    >
                        Dell Solutions
                    </ToolbarButton>


                    {MainMenuIsButtons ? "" : <div className='plain-divider'></div>}
                    < ToolbarButton
                        buttonId="btnPartnerSolutions"
                        active={"btnPartnerSolutions" === selectedButton}
                        selectedButton={selectedButton}
                        buttonName="Partner Solutions"
                        handleButtonClick={async (buttonId, buttonName) => {
                            if (selectedButton === buttonId) {
                                // if same button clicked again, reset screen
                                resetScreen();
                                return;
                            }
                            setShowCardContainer(true);
                            setUseCaseMapping(false);
                            // handleButtonClick(buttonId);
                            setGlobalState("useCase", 0);
                            setGlobalState("HoverUseCaseId", 0);
                            setGlobalState("IsTourOpen", false);
                            setSelectedButton("btnPartnerSolutions");
                            if (extraData[5][0] == null) {
                                const baseAPIUrl = `${BaseAPI}solutions`;
                                const address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB_IOC}&type=V` : `../../${ApplicationDB_IOC}/solutions/V.json`; //address for fetching sectiondata
                                // CHANGES HERE
                                try {
                                    // console.log("API CALLED");
                                    const response = await fetch(address); //fetch section data files for specific config id
                                    const data = await response.json();
                                    extraData[5].push(data);
                                } catch (error) {
                                    // console.error("Error fetching data:", error);
                                }
                            }

                            console.log(extraData[5][0]);
                            setSectionData(extraData[5][0].Solutions);
                            setButtonType("D");
                            setGlobalState("IsBackgroundBlur", false);
                            setGlobalState("showDC", false);
                            setGlobalState("showUC", false);
                            setUI_Element("popuptoolbar");
                            setGlobalState("IsButtonContainer", true);
                        }}
                        handleMenuClick={handleClick}
                        MainMenuIsButtons={MainMenuIsButtons}
                    >
                        Partner Solutions
                    </ToolbarButton >
                    {MainMenuIsButtons ? "" : <div className='plain-divider'></div>}

                    < ToolbarButton
                        forwardRef={buttonRef}
                        buttonId="watchvideo"
                        id="watchvideo"
                        selectedButton={selectedButton}
                        active={"tour" === selectedButton}
                        buttonName="Immersive Overview"
                        handleButtonClick={async (buttonId, buttonName) => {
                            if (!playAndPause) {
                                setGlobalState("playAndPause", true)
                            }
                            setGlobalState("ApplicationDB", ApplicationDB_IOC)
                            if (selectedButton === buttonId) {
                                // if same button clicked again, reset screen
                                resetScreen();
                                return;
                            }

                            setSelectedButton(buttonId);
                            setPlayVideo(true);
                            return;
                        }}
                        handleMenuClick={() => { }}
                        MainMenuIsButtons={MainMenuIsButtons}
                    >
                        Watch Video
                    </ToolbarButton >
                </div >
            </div >


            {/* Display elements if clicked */}

            {/* Cards or DVS tab */}
            <MenuDispensor
                buttonType={buttonType}
                sectionData={sectionData}
                ui_element={ui_Element}
                buttonId={selectedButton}
                useCaseMapping={useCaseMapping}
                handleMenuItemClick={handleMenuItemClick}
                anchorEl={anchorEl}
                handleClose={handleClose}
                open={open}
                alignItems={alignItems}
                handlePlayStory={handlePlayStory}
                showCardContainer={showCardContainer}
                IsGuidedTourOpen={IsGuidedTourOpen}
            />

            {playVideo &&
                <div>
                    <video
                        autoPlay
                        className="iocplayer"
                        preload="auto"
                        id="um-video"
                        controls
                        src={um1}
                        onEnded={resetScreen}
                        style={{ zIndex: 99999999999 }}
                    >
                    </video>
                    <div className="content-shadow-rect"></div>
                </div>
            }

            {/* UseCases/Guided Tour tab */}
            {/* {showTour && fetched && <UseCase steps={stepData} useCaseID={5} />} */}
        </div >
    );
};

export default ToolbarMenu_IOC;

