import React from "react";
import PropTypes from "prop-types";
import "../css/toolbar.css";
import InitializeGoogleAnalytics from "./googleanalytics.tsx";
import TrackGoogleAnalyticsEvent from "./googleanalytics.tsx";
import { ApplicationDB } from "../../assets/assetsLocation.js";
const ToolbarButton = ({
  reset,
  buttonId,
  buttonName,
  selectedButton,
  handleButtonClick,
  handleMenuClick,
  open,
  children,
  id,
  isDisable,
  MainMenuIsButtons
}) => {
  const handleClick = () => {
    InitializeGoogleAnalytics();
    TrackGoogleAnalyticsEvent("toolbar button", ApplicationDB, buttonId);
    handleButtonClick(buttonId, buttonName);
  };
  return (
    <>
      {MainMenuIsButtons ? (
        <button
          className={`toolbar-button ${selectedButton === buttonId ? "selected" : ""
            }`}
          onClick={(e) => { handleClick(); handleMenuClick(e) }}
          id={buttonId}
          disabled={isDisable}
        // style={{boxShadow:"rgba(0, 0, 0, 0.4) 0px 25px 20px 0px"}}
        >
          {children}
        </button>
      ) : (
        <> <button
          className={`plain${reset=== true? "-reset":""}-toolbar-button ${selectedButton === buttonId ? "plain-button-selected" : ""
            }`}
          aria-controls={open ? 'fade-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={(e) => { handleClick(); handleMenuClick(e) }}
          id={buttonId}
          disabled={isDisable}
        // style={{fontWeight:buttonId == "reset" && 'bold'}}
        >
          {children}
        </button>
        </>
      )}
    </>
  );
};

ToolbarButton.propTypes = {
  reset: PropTypes.bool,
  buttonId: PropTypes.string.isRequired,
  selectedButton: PropTypes.string,
  handleButtonClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

ToolbarButton.defaultProps = {
  reset: false,
};

export default ToolbarButton;
