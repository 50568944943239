import { assetsLocation, ApplicationDB, packageApp } from "../../assets/assetsLocation";
import "../css/Welcome.css";

const Welcome = ({WelcomeData, WelcomeData1, count, handleSkip, handleNext, label=""}) => {
	return (
		<div className="Welcome-card-container" style={{ zIndex: 99999999999 }}>
			<div>
				<img
					alt="test"
					width={"100%"}
					src={!packageApp ? `${assetsLocation}${ApplicationDB}/graphics/${`welcome${label}${count+1}.gif`}` : `../../${ApplicationDB}/graphics/welcome${label}${count+1}.gif`}
				/>
			</div>
			<div className="Welcome-Tour-box-title">
				<div className="wel-title"> {WelcomeData[count]}</div>
				<div className="wel-description">{WelcomeData1[count]}</div>
			</div>
			<div style={{ padding: "3%" }}>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between"
					}}
				>
					<div className="welcome-page">
						{[0, 1, 2, 3, 4, 5].map((item) => {
							return (
								<svg
									width="1vh"
									height="1vh"
									viewBox="0 0 10 10"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<circle
										className={item === count ? 'welcome-dot-circle-fill' : 'welcome-dot-circle'}
										cx="5"
										cy="5"
										r="4.5"
									/>
								</svg>
							);
						})}
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "end",
							alignItems: "center",
							width: "50%",
							gap: "3%",
						}}
					>
						<div
							className="welcome-btn"
							onClick={() => handleSkip()}
						>
							Skip
						</div>
						<div className="welcome-next-btn" onClick={() => handleNext()}>
							{count === 5 ? "Start tour" : "Next"}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Welcome;