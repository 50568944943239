import "../utils/css/UrbanMobility.css";
import { setGlobalState, useGlobalState } from "../utils/state";
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import EnterFactory from "../assets/Start icon.png";
import { gsap } from 'gsap';
import ManufacturingBG from "../assets/Manufacturing BG.mp4";
import React, { Suspense, lazy, useEffect } from "react";
import Spinner from "../utils/libraries/Spinner";
import { ApplicationDB_IOC, assetsLocation, packageApp } from "../assets/assetsLocation";
import { useState } from "react";
import { BaseAPI } from "../assets/assetsLocation";
import Landscape from "../utils/libraries/Landscape";
import Delayed from "../utils/libraries/Delayed";
import { Vector3 } from "@babylonjs/core";
import { spiralAnimation, rotateToTarget } from "../utils/libraries/CameraUtils";
// import umpano_mpgp0u from '../assets/umpano_mpgp0u.mp4'

import ToolbarMenu_IOC from "./toolbar_IOC.jsx";

const Home = lazy(() => import("../pages/home_IOC.jsx"));

function IOC() {
    const location = useLocation();
    const navbuttontext = "Reset";
    const [useCase, setUseCase] = useGlobalState("useCase");
    const [IsLoading, setIsLoading] = useGlobalState("IsLoading");
    const [extraData, setExtraData] = useState([[], [], [], [], [], [], [], [], [], [], [],]);
    const [fetched, setFetched] = useState(false);
    const [IsBackgroundBlur, setIsBackgroundBlur] = useGlobalState("IsBackgroundBlur");
    const [scene, setScene] = useGlobalState("scene");
    const [resetting, setResetting] = useState(false);
    const [ucList, setUcList] = useState(null);


    useEffect(() => {
        setGlobalState("ApplicationDB", ApplicationDB_IOC);
    }, []);

    async function fetchData() {
        for (var id = 0; id < 9; id++) {
            var baseAPIUrl;
            var address;
            if (id === 8) {
                baseAPIUrl = `${BaseAPI}use_case_list_segment/`;
                address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB_IOC}&startID=100` : `../../${ApplicationDB_IOC}/use_case_list_segment/100.json`; //address for fetching sectiondata
            } else if (id === 5 || id === 3) {
                baseAPIUrl = `${BaseAPI}solutions`;
                address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB_IOC}&type=U` : `../../${ApplicationDB_IOC}/solutions/U.json`; //address for fetching sectiondata
            } else if (id === 6) {
                baseAPIUrl = `${BaseAPI}solutions`;
                address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB_IOC}&type=V` : `../../${ApplicationDB_IOC}/solutions/V.json`; //address for fetching sectiondata
            } else {
                baseAPIUrl = `${BaseAPI}section/`;
                address = !packageApp ? `${baseAPIUrl + (100 + id)}?db=${ApplicationDB_IOC}` : `../../${ApplicationDB_IOC}/section/${(100+id)}.json`; //address for fetching sectiondata
            }
            // CHANGES HERE
            try {
                // console.log("API CALLED");
                const response = await fetch(address); //fetch section data files for specific config id
                const data = await response.json();
                extraData[id - 1].push(data);
                if (id === 8) {
                    setGlobalState("use_case_list", data);
										setUcList(data.use_case_list);
                }
            } catch (error) {
                // console.error("Error fetching data:", error);
            }
        }
        setFetched(true);
    }

    useEffect(() => {
        setGlobalState("currentZoomedSection", String(useCase).substring(2));
    }, [useCase]);

    useEffect(() => {
        fetchData();
    }, []);
    const showHotspots = (name) => {
        if (!scene) return;
        const texture = scene.getTextureByName('myUI');
        const names = ["IOC"];

        names.forEach((curr) => {
            const enable = curr == name;
            for (var i = 100; i <= 110; i++) {
                const currMesh = scene.getMeshByName(`${curr}-${i}-fake-mesh`);
                const currContainer = texture.getControlByName(`${curr}-${i}-container`);
                if (!currMesh || !currContainer) continue;
                currMesh.setEnabled(enable);
                currContainer.isVisible = enable;
            }
        });
    }

    const resetCamera = () => {
        if (!scene) return;
        if (resetting) return;
        setResetting(true);
        const arcRotateCamera = scene.getCameraByName('camera-2');
        const cam3 = scene.getCameraByName('camera-3');
        const canvas = document.getElementsByClassName("main-canvas")[0];

        scene.activeCamera.computeWorldMatrix();
        const initTarget = scene.activeCamera.target.clone();
        const initPosition = scene.activeCamera.position.clone();
        cam3.position.copyFrom(initPosition);
        cam3.setTarget(initTarget);
        arcRotateCamera.restoreState();
        arcRotateCamera.computeWorldMatrix();
        scene.activeCamera = cam3;
        showHotspots("usecase");
        spiralAnimation(scene, initTarget, initPosition, arcRotateCamera.position, 1000, 1, rotateToTarget, scene, arcRotateCamera.target, cam3, .4, (arcRotateCamera, canvas) => { arcRotateCamera.detachControl(); scene.activeCamera = arcRotateCamera; arcRotateCamera.attachControl(canvas, true); setResetting(false); }, arcRotateCamera, canvas);
    }

    if (fetched) {
        return (
            <>
                <Landscape />
                <div className="App">
                    <div className={`wrapper home-wrapper ${IsBackgroundBlur ? "backgroung-blur" : ""}`}>
                        <Suspense fallback={<Spinner />}>
                            <Home extraData={ucList} showHotspots={showHotspots} />
                        </Suspense>
                        {useCase !== 0 ? (
                            <video
                                id="bgvideo"
                                autoPlay="autoplay"
                                preload="auto"
                                className="bg manufacturing-bg-video"
                                src={!packageApp ? `${assetsLocation}${ApplicationDB_IOC}/graphics/${useCase}.mp4` : `../../${ApplicationDB_IOC}/graphics/${useCase}.mp4`}
                                muted
                                loop
                                playsInline
                                onError={(e) => e.target.style.display = 'none'}
                            ></video>
                        ) : (
                            ""
                        )}
                    </div>

                    <motion.div
                        className="wrapper"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 2 }}
                    >
                        {IsLoading ? (
                            ""
                        ) : (
                            <Routes location={location} key={location.pathname}>
                                <Route
                                    path="/:toPress?/:loadID?"
                                    element={<ToolbarMenu_IOC extraData={extraData} showHotspots={showHotspots} resetCamera={resetCamera} />}
                                />
                            </Routes>
                        )}
                    </motion.div>
                </div>
            </>
        );
    }

}
export default IOC;