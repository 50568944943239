import React, { useState, useEffect, useRef } from "react";
import { Routes, Route, useLocation } from 'react-router-dom';
import ToolbarButton from "../utils/libraries/ToolbarButton";
import MenuDispensor from "../utils/libraries/MenuDispensor";
import { useParams, useNavigate } from "react-router-dom";
import { setGlobalState, useGlobalState } from "../utils/state";
import { Howler, Howl } from "howler";
import "../utils/css/mainPage.css";
import { BaseAPI, CityDB, ApplicationDB, assetsLocation, packageApp } from "../assets/assetsLocation";
import { setTourState } from "../hooks/animations";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker";
import { CSSTransition } from "react-transition-group";
import { resetLights } from "../utils/libraries/LightUtils";

const ToolbarManu = (props) => {
	const MainMenuIsButtons = false;
	const location = useLocation();
	const buttonRef = useRef(null);
	const { toPress, loadID } = useParams();
	const [extraData, setExtraData] = useState([[], [], [], [], [], [], [], [], [], [], []]);
	const [selectedButton, setSelectedButton] = useGlobalState("selectedButton");
	const [showCardContainer, setShowCardContainer] = useState(false);
	const [isResetClick, setIsResetClick] = useState(false);
	const [sectionData, setSectionData] = useState([]);

	const [ui_Element, setUI_Element] = useState(null);

	const [showTour, setShowTour] = useGlobalState("showTour");
	const [dimBg, setDimBg] = useGlobalState("dimBg");
	const [buttonType, setButtonType] = useState("");

	const [showUC, setShowUC] = useGlobalState("showUC");

	const [HoverId, setHoverId] = useGlobalState("HoverId");
	const [HoverLabel, setHoverLabel] = useGlobalState("HoverLabel");
	const [useCase, setUseCase] = useGlobalState("useCase");
	const [isTourOpen, setIsTourOpen] = useGlobalState("IsTourOpen");
	const [isHomeButtonClick, setIsHomeButtonClick] = useGlobalState("IsHomeButtonClick");
	const [clientXPosition1, setClientXPosition1] = useGlobalState("clientXPosition1");
	const [clientYPosition1, setClientYPosition1] = useGlobalState("clientYPosition1");
	const [playAndPause, setPlayAndPause] = useGlobalState("playAndPause");
	const gaEventTracker = useAnalyticsEventTracker("ToolBarMenu");
	const [anchorEl, setAnchorEl] = useState(null);
	const [scene, setScene] = useGlobalState("scene");
	const [useCaseMapping, setUseCaseMapping] = useState(false);

	let alignItems = true;

	const open = anchorEl;
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
		setSelectedButton("selectedButton")
	};
	const handleMenuItemClick = () => {
		setAnchorEl(null);
		// setSelectedButton("selectedButton")
	};
	const links = new Map([
		["use_case_stories", "button8"],
		["outcomes", "button4"],
		["solutions", "button3"],
		["partners", "button5"]
	]);

	useEffect(() => {
		if (selectedButton == "tour" && isTourOpen == false) {
			setSelectedButton(null);
		}
	}, [isTourOpen]);

	const resetScreen = () => {
		resetLights(scene);
		setGlobalState("IsBackgroundBlur", false);
		setGlobalState("HoverUseCaseId", 0);
		// setGlobalState("HoverId", 0);
		setTourState(false);
		setSelectedButton(null);
		setShowCardContainer(false);
		// setUI_Element(null);
		setGlobalState("useCase", 0);
		// setGlobalState("mapped_use_case", null);
		setShowUC(false);
		// setDimBg(false);
		setGlobalState("currentZoomedSection", 0);
		setGlobalState("showUC", false);
		props.showHotspots(true);
		Howler.stop();
	};

	const handleTourButtonClick = (buttonId) => {
		setGlobalState("IsBackgroundBlur", false);
		if (selectedButton === buttonId) {
			if (isTourOpen) {
				setTourState(false);
				Howler.stop();
				props.resetCamera();
				setGlobalState("UCTourId", 0);
				setGlobalState("IsTourOpen", false);
			} // if same button clicked again, reset screen
			resetScreen();
			return;
		} else {
			setTourState(true);
			setSelectedButton(buttonId);
			setUI_Element("");
			setShowCardContainer(false);
			setGlobalState("IsTourOpen", true);
			setGlobalState("useCase", 0);
			Howler.stop();
		}
	};

	useEffect(() => {
		if (toPress != null) {
			if (toPress === "tour") {
				handleTourButtonClick(toPress);
			} else {
				handleButtonClick(links.get(toPress));
			}
		}
	}, [toPress]);


	const handlePlayStory = () => {
		if (HoverId > 0) {
			setGlobalState("HoverUseCaseId", HoverId);
		}
		handleUseCaseButtonClick("button8");
		setGlobalState("IsButtonContainer", false);
	}
	useEffect(() => {
		if (isHomeButtonClick) {
			// setUI_Element("");
			setGlobalState("useCase", 0);
			setGlobalState("HoverUseCaseId", 0);
			setSelectedButton(null);
			// setGlobalState("IsButtonContainer", false);
		}
	}, [isHomeButtonClick]);

	const handleUseCaseButtonClick = async (buttonId) => {
		setGlobalState("IsHomeButtonClick", false);
		setGlobalState("ApplicationDB", ApplicationDB);
		Howler.stop();
		setUI_Element("");
		if (selectedButton === buttonId) {
			// if same button clicked again, reset screen
			resetScreen();
			return;
		}
		setSelectedButton(buttonId);
		try {
			const baseAPIUrl = !packageApp ? `${BaseAPI}use_case_list_segment/?db=${ApplicationDB}&startID=0` : `../../${ApplicationDB}/use_case_list_segment/0.json`;
			const id = buttonId.at(-1);
			const address = baseAPIUrl; //address for fetching sectiondata
			// const address = baseAPIUrl + id;//address for fetching sectiondata
			const response = await fetch(address); //fetch section data files for specific config id
			const data = await response.json();

			if (buttonId === "button8") {
				setButtonType("Use_case");
				setGlobalState("IsButtonContainer", true);
				setUI_Element("popuptoolbar");
			} else {
				setUI_Element("cards");
			}
			setSectionData(data.use_case_list);

			setShowCardContainer(true);
			setDimBg(true);
			if (buttonId === "button8") {
				setShowUC(true);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
		return;
	};

	async function fetchData() {
		for (var id = 0; id < 9; id++) {
			var baseAPIUrl;
			var address;
			if (id === 8) {
				baseAPIUrl = `${BaseAPI}use_case_list_segment/`;
				address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB}&startID=0` : `../../${ApplicationDB}/use_case_list_segment/0.json`; //address for fetching sectiondata
			}
			else if (id === 5 || id === 3) {
				baseAPIUrl = `${BaseAPI}solutions`;
				address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB}` : `../../${ApplicationDB}/solutions.json`; //address for fetching sectiondata
			} else {
				baseAPIUrl = `${BaseAPI}section/`;
				address = !packageApp ? `${baseAPIUrl + id}?db=${ApplicationDB}` : `../../${ApplicationDB}/section/${id}.json`; //address for fetching sectiondata
			}
			// CHANGES HERE
			try {
				// console.log("API CALLED");
				const response = await fetch(address); //fetch section data files for specific config id
				const data = await response.json();
				extraData[id - 1].push(data);
				if (id === 8) {
					setGlobalState("use_case_list", data);
				}
			} catch (error) {
				// console.error("Error fetching data:", error);
			}
		}
	}

	useEffect(() => {
		setExtraData(props.extraData);
	}, [props.extraData]);

	async function fetchAudio() {
		const baseAPIUrl = `${BaseAPI}use_case_list_segment/`;
		const address = !packageApp ? `${baseAPIUrl}?db = ${ApplicationDB}&startID=0` : `../../${ApplicationDB}/use_case_list_segment/0.json`;
		const response = await fetch(address);
		const data = await response.json();

		let Vosound;
		const audioClips = new Map();
		const audio_Paths = new Map();

		data.use_case_list.forEach((uc) => {
			const id = uc.use_case_id;
			const src_url = !packageApp ?
				`${assetsLocation}${ApplicationDB}/audio/uc` + String(id) + "/" : `../../${ApplicationDB}/audio/uc${id}/`;
			const path = src_url + "10.mp3";
			try {
				Vosound = new Howl({
					src: path,
					html5: true,
					onpause: false,
					preload: true,
				});
				audioClips.set(id, Vosound);
				audio_Paths.set(id, path);
			} catch {
			}
		});
		setGlobalState("audioVO1", audioClips);
		setGlobalState("audioPathVO1", audio_Paths);

    const audioClips2 = new Map();
    const audio_Paths2 = new Map();
    data.use_case_list.forEach((uc) => {
      const id = uc.use_case_id;
      const src_url = !packageApp ?
        `${assetsLocation}${ApplicationDB}/audio/uc` + String(id) + "/" : `../../${ApplicationDB}/audio/uc${id}/`;
      const path = src_url + "11.mp3";
      try {
        Vosound = new Howl({
          src: path,
          html5: true,
          onpause: false,
          preload: true,
        });
        audioClips2.set(id, Vosound);
        audio_Paths2.set(id, path);
      } catch {
      }
    });
    setGlobalState("audioVO2", audioClips2);
    setGlobalState("audioPathVO2", audio_Paths2);
	}


	useEffect(() => {
		fetchAudio();
	}, []);

	const handleButtonClick = async (buttonId) => {
		// window.history.pushState("", "", "/manufacturing/" + buttonId);
		if (selectedButton === "button8") {
			fetchAudio();
		}
		if (selectedButton === buttonId) {
			// if same button clicked again, reset screen
			// window.history.pushState("", "", "/manufacturing/");
			//  if (buttonId !== "button8" && buttonId !== "button3" && buttonId !== "button5" ) {
			resetScreen();
			return;
			// }
		}

		// console.log("buttonName",buttonName);
		gaEventTracker(buttonId);
		setTourState(false);
		if (!playAndPause) {
			setGlobalState("playAndPause", true);
		}
		setGlobalState("IsAutoPlay", false);
		setGlobalState("IsHomeButtonClick", false);
		setGlobalState("ApplicationDB", ApplicationDB);
		if (isTourOpen) {
			setGlobalState("UCTourId", 0);
			// document.getElementById("close-btn").click();
			props.resetCamera();
		}
		Howler.stop();
		setSelectedButton(buttonId);

		if (buttonId == "button4" || buttonId == "button2" || buttonId == "button7") {
			setGlobalState("IsBackgroundBlur", true);
		} else {
			setGlobalState("IsBackgroundBlur", false);
		}
		if (buttonId !== "button8") {
			setGlobalState("useCase", 0);
			setGlobalState("HoverUseCaseId", 0);
			setGlobalState("showUC", false);
		}
		if (buttonId !== "tour") {
			setGlobalState("IsTourOpen", false);
		}

		try {
			// Fetch data from API
			let baseAPIUrl;
			let address;
			const id = buttonId.at(-1);
			if (buttonId === "button8") {
				baseAPIUrl = `${BaseAPI}use_case_list_segment/`;
				address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB}&startID=0` : `../../${ApplicationDB}/use_case_list_segment/0.json`; //address for fetching sectiondata
			}
			else if (buttonId === "button5" || buttonId === "button3") {
				baseAPIUrl = `${BaseAPI}solutions`;
				address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB}` : `../../${ApplicationDB}/solutions.json`; //address for fetching sectiondata
			} else {
				baseAPIUrl = `${BaseAPI}section/`;
				address = !packageApp ? `${baseAPIUrl + id}?db=${ApplicationDB}` : `../../${ApplicationDB}/section/${id}.json`; //address for fetching sectiondata
			}
			// CHANGES HERE
			if (extraData[id - 1].length == 0) {
				// console.log("API CALLED");
				const response = await fetch(address); //fetch section data files for specific config id
				const data = await response.json();
				extraData[id - 1].push(data);
				// console.log(extraData[id-1].length);
			}

			const data = extraData[id - 1][0];

			// Assign UI Element to display data
			if (buttonId === "button3") {
				setGlobalState("showDC", false);
				setButtonType("D");
				setGlobalState("showUC", false);
			}
			if (buttonId === "button5") {
				setGlobalState("showDC", false);
				setButtonType("P");
				setGlobalState("showUC", false);
			}
			if (buttonId === "button8") {
				setButtonType("Use_case");
				setGlobalState("HoverUseCaseId", 0);
				setGlobalState("IsButtonContainer", true);
			}
			if (
				buttonId === "button3" ||
				buttonId === "button5" ||
				buttonId === "button8"
			) {
				setUI_Element("popuptoolbar");
			} else {
				setUI_Element("cards");
			}
			if (buttonId === "button8") {
				setSectionData(data.use_case_list);
			} else if (buttonId === "button5" || buttonId === "button3") {
				let dataUsed = [];
				data.Solutions.map((item) => {
					if (buttonId === "button3" && item.soln_type == "D") {
						dataUsed.push(item);
					} else if (buttonId === "button5" && item.soln_type == "P") {
						dataUsed.push(item);
					}
				});
				setSectionData(dataUsed);
			} else {
				setSectionData(data.SectionData);
			}

			setShowCardContainer(true);
			setDimBg(true);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
		return;
	};
	const handleResetButtonClick = (buttonId) => {
		// if(buttonId == selectedButton){
		//   setSelectedButton(null)
		// }else{
		//   setSelectedButton(buttonId)
		// }
		setGlobalState("IsButtonContainer", true);
		setGlobalState("currentZoomedSection", 0);
		setGlobalState("IsBackgroundBlur", false);
		if (MainMenuIsButtons) {
			setIsResetClick(true)
		}
		setTimeout(() => {
			setIsResetClick(false)
		}, 1000);
		setTourState(false);
		setSelectedButton(null);
		setShowCardContainer(false);
		setGlobalState("showDC", false);
		setGlobalState("IsTourOpen", false);
		setGlobalState("UCTourId", 0);
		setGlobalState("IsHomeButtonClick", true);
		props.showHotspots(true);
		resetScreen();
		props.resetCamera();
		Howler.stop();
		// document.getElementById("close-btn").click();
	}

	return (
		<div>
			<CSSTransition
				in={HoverId > 0}
				timeout={300} // Duration of the animation in milliseconds
				classNames="animationHover" // Your CSS class for animations
				unmountOnExit
				mountOnEnter
			>
				<div style={{ top: clientYPosition1, left: clientXPosition1 }} className="hot-spot-subMenu">
					<div>
						<div className="hover-label-text">{HoverLabel}</div>
						<hr style={{ marginTop: "5%" }} className="card-divider"></hr>
					</div>
					<div className="button-group" >
						{(isTourOpen || useCase !== 0) ? "" :
							(scene && scene.activeCamera && scene.activeCamera.name.includes("security")) == true ?
								<div className="zoom-in" onClick={() => props.resetCamera()}>Zoom-out</div>
								:
								<div className="zoom-in" onClick={() => setGlobalState("currentZoomedSection", HoverId)}>Zoom-in</div>
						}
						<div className="learn-more" onClick={() => handlePlayStory()}>{useCase !== 0 ? "End Story" : "Learn More"}</div>
					</div>
				</div>
			</CSSTransition>

			<div style={{ display: 'flex' }}>
				<div className={`${MainMenuIsButtons ? "toolbar reset-toolbar" : "plain-reset-toolbar"} `} >
					<ToolbarButton
						forwardRef={buttonRef}
						buttonId="reset"
						id="reset"
						selectedButton={""}
						active={"reset" === selectedButton}
						buttonName="Reset the Experience"
						// handleButtonClick={handleButtonClick}
						handleButtonClick={handleResetButtonClick}
						handleMenuClick={() => { }}
						MainMenuIsButtons={MainMenuIsButtons}
					>
						{/* <div className="pulse"></div> */}
						{/* {MainMenuIsButtons ? <span className="blink" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>{isResetClick ? <img width={"40px"} height={"40px"} src={rightClick}/> :"Reset the Experience"}</span>: */}
						{MainMenuIsButtons ? <span className="blink" style={{ gap: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

							<svg width="4vh" height="4vh" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g filter="url(#filter0_bd_38_67)">
									<rect x="6" y="3" width="52" height="52" rx="26" fill="#D8D8D8" />
								</g>
								<path d="M31.6279 40C28.3821 40 25.6323 38.8867 23.3787 36.6601C21.1262 34.4324 20 31.714 20 28.5049C20 25.2959 21.1262 22.5764 23.3787 20.3465C25.6323 18.1155 28.3821 17 31.6279 17C33.6024 17 35.4507 17.4638 37.1728 18.3915C38.8959 19.3192 40.2846 20.6143 41.3389 22.2769V17H43V25.5921H34.3123V23.9493H40.4585C39.5936 22.3119 38.3765 21.0179 36.8073 20.0672C35.2403 19.1176 33.5138 18.6429 31.6279 18.6429C28.8594 18.6429 26.5061 19.6012 24.5681 21.5179C22.6301 23.4345 21.6611 25.7619 21.6611 28.5C21.6611 31.2381 22.6301 33.5655 24.5681 35.4821C26.5061 37.3988 28.8594 38.3571 31.6279 38.3571C33.7597 38.3571 35.6838 37.7548 37.4003 36.55C39.1168 35.3452 40.3211 33.7571 41.0133 31.7857H42.7757C42.0437 34.2456 40.6523 36.2296 38.6013 37.7378C36.5504 39.2459 34.2259 40 31.6279 40Z" fill="black" />
								<defs>
									<filter id="filter0_bd_38_67" x="0" y="-3" width="64" height="67" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
										<feFlood flood-opacity="0" result="BackgroundImageFix" />
										<feGaussianBlur in="BackgroundImageFix" stdDeviation="3" />
										<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_38_67" />
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
										<feOffset dy="3" />
										<feGaussianBlur stdDeviation="3" />
										<feComposite in2="hardAlpha" operator="out" />
										<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
										<feBlend mode="normal" in2="effect1_backgroundBlur_38_67" result="effect2_dropShadow_38_67" />
										<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_38_67" result="shape" />
									</filter>
								</defs>
							</svg>

							Reset</span> :
							<span className="" style={{ gap: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

								<svg width="4vh" height="4vh" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
									<g filter="url(#filter0_bd_38_67)">
										<rect x="6" y="3" width="52" height="52" rx="26" fill="#D8D8D8" />
									</g>
									<path d="M31.6279 40C28.3821 40 25.6323 38.8867 23.3787 36.6601C21.1262 34.4324 20 31.714 20 28.5049C20 25.2959 21.1262 22.5764 23.3787 20.3465C25.6323 18.1155 28.3821 17 31.6279 17C33.6024 17 35.4507 17.4638 37.1728 18.3915C38.8959 19.3192 40.2846 20.6143 41.3389 22.2769V17H43V25.5921H34.3123V23.9493H40.4585C39.5936 22.3119 38.3765 21.0179 36.8073 20.0672C35.2403 19.1176 33.5138 18.6429 31.6279 18.6429C28.8594 18.6429 26.5061 19.6012 24.5681 21.5179C22.6301 23.4345 21.6611 25.7619 21.6611 28.5C21.6611 31.2381 22.6301 33.5655 24.5681 35.4821C26.5061 37.3988 28.8594 38.3571 31.6279 38.3571C33.7597 38.3571 35.6838 37.7548 37.4003 36.55C39.1168 35.3452 40.3211 33.7571 41.0133 31.7857H42.7757C42.0437 34.2456 40.6523 36.2296 38.6013 37.7378C36.5504 39.2459 34.2259 40 31.6279 40Z" fill="black" />
									<defs>
										<filter id="filter0_bd_38_67" x="0" y="-3" width="64" height="67" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
											<feFlood flood-opacity="0" result="BackgroundImageFix" />
											<feGaussianBlur in="BackgroundImageFix" stdDeviation="3" />
											<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_38_67" />
											<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
											<feOffset dy="3" />
											<feGaussianBlur stdDeviation="3" />
											<feComposite in2="hardAlpha" operator="out" />
											<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
											<feBlend mode="normal" in2="effect1_backgroundBlur_38_67" result="effect2_dropShadow_38_67" />
											<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_38_67" result="shape" />
										</filter>
									</defs>
								</svg>

								Reset</span>}
					</ToolbarButton>
				</div>
				<div className={`${MainMenuIsButtons ? "toolbar" : "plain-toolbar"} `}>
					<ToolbarButton // Guided Tour button
						buttonId="button4"
						selectedButton={selectedButton}
						active={"button4" === selectedButton}
						buttonName="Business Impacts"
						handleButtonClick={async (buttonId) => {
							if (selectedButton === buttonId) {
								resetScreen();
								return;
							}

							gaEventTracker(buttonId);
							setTourState(false);
							if (!playAndPause) {
								setGlobalState("playAndPause", true);
							}
							setGlobalState("IsAutoPlay", false);
							setGlobalState("IsHomeButtonClick", false);
							setGlobalState("ApplicationDB", ApplicationDB);
							if (isTourOpen) {
								setGlobalState("UCTourId", 0);
								props.resetCamera();
							}
							Howler.stop();
							setSelectedButton(buttonId);
							setGlobalState("IsBackgroundBlur", true);
							setGlobalState("useCase", 0);
							setGlobalState("HoverUseCaseId", 0);
							setGlobalState("showUC", false);
							setGlobalState("IsTourOpen", false);

							try {
								// Fetch data from API
								let baseAPIUrl;
								let address;
								const id = buttonId.at(-1);
								baseAPIUrl = `${BaseAPI}section/`;
								address = !packageApp ? `${baseAPIUrl + id}?db=${ApplicationDB}` : `../../${ApplicationDB}/section/${id}.json`; //address for fetching sectiondata
								// CHANGES HERE
								if (extraData[id - 1].length == 0) {
									// console.log("API CALLED");
									const response = await fetch(address); //fetch section data files for specific config id
									const data = await response.json();
									extraData[id - 1].push(data);
									// console.log(extraData[id-1].length);
								}

								const data = extraData[id - 1][0];
								setUI_Element("cards");
								setSectionData(data.SectionData);
								setShowCardContainer(true);
								setDimBg(true);
								setUseCaseMapping(true);
							} catch (error) {
								console.error("Error fetching data:", error);
							}
							return;
						}}
						handleMenuClick={() => { }}
						MainMenuIsButtons={MainMenuIsButtons}
					>
						Business Impacts
					</ToolbarButton>
					{MainMenuIsButtons ? "" : <div className='plain-divider'></div>}

					<ToolbarButton // Guided Tour button
						buttonId="button8"
						selectedButton={selectedButton}
						active={"button8" === selectedButton}
						buttonName="Use Cases Enabled"
						handleButtonClick={async (buttonId, buttonName) => {
							fetchAudio();
							if (selectedButton === buttonId) {
								resetScreen();
								// return;    
							}

							gaEventTracker(buttonId);
							setTourState(false);
							if (!playAndPause) {
								setGlobalState("playAndPause", true);
							}
							setGlobalState("IsAutoPlay", false);
							setGlobalState("IsHomeButtonClick", false);
							setGlobalState("ApplicationDB", ApplicationDB);
							if (isTourOpen) {
								setGlobalState("UCTourId", 0);
								props.resetCamera();
							}
							Howler.stop();
							setSelectedButton(buttonId);

							setGlobalState("IsBackgroundBlur", false);
							setGlobalState("useCase", 0);
							setGlobalState("HoverUseCaseId", 0);
							setGlobalState("showUC", false);
							setGlobalState("playUCDirectly", false);

							try {
								// Fetch data from API
								let baseAPIUrl;
								let address;
								if (buttonId === "button8") {
									baseAPIUrl = `${BaseAPI}use_case_list_segment`;
									address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB}&startID=0` : `../../${ApplicationDB}/use_case_list_segment/0.json`; //address for fetching sectiondata
								}

								// CHANGES HERE
								if (extraData[7].length == 0) {
									const response = await fetch(address); //fetch section data files for specific config id
									const data = await response.json();
									extraData[7].push(data);
								}

								const data = extraData[7][0];

								// Assign UI Element to display data
								setButtonType("Use_case");
								setGlobalState("HoverUseCaseId", 0);
								setGlobalState("IsButtonContainer", true);
								setUI_Element("popuptoolbar");
								setSectionData(data.use_case_list);

								setShowCardContainer(true);
								setDimBg(true);
							} catch (error) {
								console.error("Error fetching data:", error);
							}
							return;
						}}

						handleMenuClick={handleClick}
						MainMenuIsButtons={MainMenuIsButtons}
					>
						Use Cases Enabled
					</ToolbarButton>
					{MainMenuIsButtons ? "" : <div className='plain-divider'></div>}

					<ToolbarButton // Outcomes button
						buttonId="button2"
						active={"button2" === selectedButton}
						selectedButton={selectedButton}
						buttonName="Edge Challenges"
						// onClick={() => handleButtonClick("1")}
						handleButtonClick={async (buttonId) => {
							if (selectedButton === buttonId) {
								resetScreen();
								return;
							}

							gaEventTracker(buttonId);
							setTourState(false);
							if (!playAndPause) {
								setGlobalState("playAndPause", true);
							}
							setGlobalState("IsAutoPlay", false);
							setGlobalState("IsHomeButtonClick", false);
							setGlobalState("ApplicationDB", ApplicationDB);
							if (isTourOpen) {
								setGlobalState("UCTourId", 0);
								props.resetCamera();
							}
							Howler.stop();
							setSelectedButton(buttonId);
							setGlobalState("IsBackgroundBlur", true);
							setGlobalState("useCase", 0);
							setGlobalState("HoverUseCaseId", 0);
							setGlobalState("showUC", false);
							setGlobalState("IsTourOpen", false);
							try {
								// Fetch data from API
								let baseAPIUrl;
								let address;
								const id = buttonId.at(-1);
								baseAPIUrl = `${BaseAPI}section/`;
								address = !packageApp ? `${baseAPIUrl + id}?db=${ApplicationDB}` : `../../${ApplicationDB}/section/${id}.json`; //address for fetching sectiondata
								// CHANGES HERE
								if (extraData[id - 1].length == 0) {
									// console.log("API CALLED");
									const response = await fetch(address); //fetch section data files for specific config id
									const data = await response.json();
									extraData[id - 1].push(data);
									// console.log(extraData[id-1].length);
								}

								const data = extraData[id - 1][0];

								// Assign UI Element to display data
								setUI_Element("cards");
								setSectionData(data.SectionData);
								setShowCardContainer(true);
								setDimBg(true);
								setUseCaseMapping(false);
							} catch (error) {
								console.error("Error fetching data:", error);
							}
							return;
						}}
						handleMenuClick={() => { }}
						MainMenuIsButtons={MainMenuIsButtons}
					>
						Edge Challenges
					</ToolbarButton>
					{MainMenuIsButtons ? "" : <div className='plain-divider'></div>}

					<ToolbarButton // Building Blocks button
						buttonId="button3"
						active={"button3" === selectedButton}
						selectedButton={selectedButton}
						buttonName="Dell Solutions"
						// onClick={() => handleButtonClick("2")}
						handleButtonClick={async (buttonId) => {
							// window.history.pushState("", "", "/manufacturing/" + buttonId);
							if (selectedButton === buttonId) {
								resetScreen();
								// return;
							}

							gaEventTracker(buttonId);
							setTourState(false);
							if (!playAndPause) {
								setGlobalState("playAndPause", true);
							}
							setGlobalState("IsAutoPlay", false);
							setGlobalState("IsButtonContainer", true);
							setGlobalState("IsHomeButtonClick", false);
							setGlobalState("ApplicationDB", ApplicationDB);
							if (isTourOpen) {
								setGlobalState("UCTourId", 0);
								props.resetCamera();
							}
							Howler.stop();
							setSelectedButton(buttonId);
							setGlobalState("IsBackgroundBlur", false);

							setGlobalState("useCase", 0);
							setGlobalState("HoverUseCaseId", 0);
							setGlobalState("showUC", false);
							setGlobalState("IsTourOpen", false);

							try {
								// Fetch data from API
								let baseAPIUrl;
								let address;
								const id = 3;
								baseAPIUrl = `${BaseAPI}solutions`;
								address = !packageApp ? `${baseAPIUrl}?db=${ApplicationDB}` : `../../${ApplicationDB}/solutions.json`; //address for fetching sectiondata
								// CHANGES HERE
								if (extraData[id - 1].length == 0) {
									// console.log("API CALLED");
									const response = await fetch(address); //fetch section data files for specific config id
									const data = await response.json();
									extraData[id - 1].push(data);
									// console.log(extraData[id-1].length);
								}

								const data = extraData[id - 1][0];

								// Assign UI Element to display data
								setGlobalState("showDC", false);
								setButtonType("D");
								setGlobalState("showUC", false);
								setUI_Element("popuptoolbar");
								let dataUsed = [];
								data.Solutions.map((item) => {
									if (item.soln_type == "D") {
										dataUsed.push(item);
									}
								});
								setSectionData(dataUsed);
								setShowCardContainer(true);
								setDimBg(true);
							} catch (error) {
								console.error("Error fetching data:", error);
							}
							return;
						}}
						handleMenuClick={handleClick}
						MainMenuIsButtons={MainMenuIsButtons}
					>
						Dell Solutions
					</ToolbarButton>
					{MainMenuIsButtons ? "" : <div className='plain-divider'></div>}

					<ToolbarButton // DVS button
						buttonId="button7"
						active={"button7" === selectedButton}
						selectedButton={selectedButton}
						buttonName="Value to Manufacturers"
						// onClick={() => handleButtonClick("3")}
						handleButtonClick={async (buttonId) => {
							if (selectedButton === buttonId) {
								resetScreen();
								return;
							}

							gaEventTracker(buttonId);
							setTourState(false);
							if (!playAndPause) {
								setGlobalState("playAndPause", true);
							}
							setGlobalState("IsAutoPlay", false);
							setGlobalState("IsHomeButtonClick", false);
							setGlobalState("ApplicationDB", ApplicationDB);
							if (isTourOpen) {
								setGlobalState("UCTourId", 0);
								props.resetCamera();
							}
							Howler.stop();
							setSelectedButton(buttonId);
							setGlobalState("IsBackgroundBlur", true);
							setGlobalState("useCase", 0);
							setGlobalState("HoverUseCaseId", 0);
							setGlobalState("showUC", false);
							setGlobalState("IsTourOpen", false);
							try {
								// Fetch data from API
								let baseAPIUrl;
								let address;
								const id = buttonId.at(-1);
								baseAPIUrl = `${BaseAPI}section/`;
								address = !packageApp ? `${baseAPIUrl + id}?db=${ApplicationDB}` : `../../${ApplicationDB}/section/${id}.json`; //address for fetching sectiondata
								// CHANGES HERE
								if (extraData[id - 1].length == 0) {
									// console.log("API CALLED");
									const response = await fetch(address); //fetch section data files for specific config id
									const data = await response.json();
									extraData[id - 1].push(data);
									// console.log(extraData[id-1].length);
								}

								const data = extraData[id - 1][0];

								// Assign UI Element to display data
								setUI_Element("cards");
								setSectionData(data.SectionData);
								setUseCaseMapping(false);
								setShowCardContainer(true);
								setDimBg(true);
							} catch (error) {
								console.error("Error fetching data:", error);
							}
							return;
						}}
						handleMenuClick={() => { }}
						MainMenuIsButtons={MainMenuIsButtons}
					>
						Value to Manufacturers
					</ToolbarButton>
					{MainMenuIsButtons ? "" : <div className='plain-divider'></div>}

					<ToolbarButton
						buttonId="button5"
						active={"button5" === selectedButton}
						selectedButton={selectedButton}
						buttonName="Partner Solutions"
						handleButtonClick={async (buttonId) => {
							if (selectedButton === buttonId) {
								resetScreen();
							}

							gaEventTracker(buttonId);
							setTourState(false);
							if (!playAndPause) {
								setGlobalState("playAndPause", true);
							}
							setGlobalState("IsAutoPlay", false);
							setGlobalState("IsButtonContainer", true);
							setGlobalState("IsHomeButtonClick", false);
							setGlobalState("ApplicationDB", ApplicationDB);
							if (isTourOpen) {
								setGlobalState("UCTourId", 0);
								props.resetCamera();
							}
							Howler.stop();
							setSelectedButton(buttonId);
							setGlobalState("IsBackgroundBlur", false);
							setGlobalState("useCase", 0);
							setGlobalState("HoverUseCaseId", 0);
							setGlobalState("showUC", false);
							setGlobalState("IsTourOpen", false);

							try {
								// Fetch data from API
								let baseAPIUrl;
								let address;
								const id = buttonId.at(-1);
								baseAPIUrl = `${BaseAPI}solutions`;
								address = !packageApp ? `${baseAPIUrl + id}?db=${ApplicationDB}` : `../../${ApplicationDB}/solutions.json`; //address for fetching sectiondata
								address = `${baseAPIUrl}?db=${ApplicationDB}`; //address for fetching sectiondata
								// CHANGES HERE
								if (extraData[id - 1].length == 0) {
									// console.log("API CALLED");
									const response = await fetch(address); //fetch section data files for specific config id
									const data = await response.json();
									extraData[id - 1].push(data);
									// console.log(extraData[id-1].length);
								}

								const data = extraData[id - 1][0];

								// Assign UI Element to display data
								setGlobalState("showDC", false);
								setButtonType("P");
								setGlobalState("showUC", false);
								setUI_Element("popuptoolbar");
								let dataUsed = [];
								data.Solutions.map((item) => {
									if (item.soln_type == "P") {
										dataUsed.push(item);
									}
								});
								setSectionData(dataUsed);
								setShowCardContainer(true);
								setDimBg(true);
							} catch (error) {
								console.error("Error fetching data:", error);
							}
							return;
						}}
						handleMenuClick={handleClick}
						MainMenuIsButtons={MainMenuIsButtons}
					>
						Partner Solutions
					</ToolbarButton>
					{MainMenuIsButtons ? "" : <div className='plain-divider'></div>}

					<ToolbarButton
						forwardRef={buttonRef}
						buttonId="tour"
						id="tour"
						selectedButton={selectedButton}
						active={"tour" === selectedButton}
						buttonName={"tour" === selectedButton ? "End Tour" : "Tour the Factory"}
						// handleButtonClick={handleButtonClick}
						handleButtonClick={handleTourButtonClick}
						handleMenuClick={() => { }}
						MainMenuIsButtons={MainMenuIsButtons}
					>
						{"tour" === selectedButton ? "End Tour" : "Tour the Factory"}
					</ToolbarButton>

				</div>
			</div>
			{/* Display elements if clicked */}

			{/* Cards or DVS tab */}
			<MenuDispensor
				buttonType={buttonType}
				sectionData={sectionData}
				ui_element={ui_Element}
				buttonId={selectedButton}
				useCaseMapping={useCaseMapping}
				anchorEl={anchorEl}
				handleMenuItemClick={handleMenuItemClick}
				handleClose={handleClose}
				handlePlayStory={handlePlayStory}
				open={open}
				alignItems={alignItems}
				showCardContainer={showCardContainer}
			/>

			{/* UseCases/Guided Tour tab */}
			{/* {showTour && fetched && <UseCase steps={stepData} useCaseID={5} />} */}
		</div>
	);
};

export default ToolbarManu;
