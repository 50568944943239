import React from 'react';
import '../utils/css/EdgeCity.css';
import manu from '../assets/SC icon.png';
import retail from '../assets/GS icon.png';
import dc from '../assets/UM icon.png';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Delayed from '../utils/libraries/Delayed';
import { setGlobalState, useGlobalState } from '../utils/state';
import Landscape from "../utils/libraries/Landscape";
import Digital_Cities from '../assets/Digital_Cities.mp4';
import ioc from '../assets/IOC Icon.png';

function DigitalCityScreen() {
    const [UmToDigitalCity, setUmToDigitalCity] = useGlobalState("UmToDigitalCity");

    const navbuttontext = '< Edge City';
    let navigate = useNavigate();

    return (
        <>
            <Landscape />
            <div className='wrapper'>

                <div className='wrapper'>
                    <video autoPlay="autoplay" preload="auto" className="bg" style={{ filter: 'none' }} src={Digital_Cities} muted playsInline></video>
                </div>

                {/* Video is 10s long */}
                <Delayed waitBeforeShow={UmToDigitalCity ? 3000 : 0}>
                    <motion.div
                        className='wrapper'
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 2 }}
                    >
                        <div className='blur-ell1'></div>
                        <div className='blur-ell3'></div>
                        <div className='blur-ell4'></div>
                        <div className='blur-rect'></div>



                        <div className='info-button1 ioc clickable' onClick={() => { navigate("/UOC") }}></div>
                        <div className='info-button manufacturing'></div>
                        <div className='info-button retail'></div>
                        <div className='info-button digcities clickable' onClick={() => { navigate("/urban-mobility") }}></div>

                        <h1 className='info-head1 ioc-text button-text clickable' onClick={() => { navigate("/UOC") }}>Unified Operations Centre</h1>
                        <h1 className='info-head manu-text clickable' onClick={() => { navigate("/safety-security") }}>Safe Cities</h1>
                        <h1 className='info-head retail-text clickable' onClick={() => { navigate("/green-sustainable") }}>Green and Sustainable</h1>
                        <h1 className='info-head dc-text clickable' onClick={() => { navigate("/urban-mobility") }}>Urban Mobility</h1>

                        <p className='info-sub1 ioc-text button-text clickable' onClick={() => { navigate("/UOC") }}>The Unified Operations Center offers a holistic approach to managing diverse domains within Digital Cities, providing comprehensive solutions.</p>
                        <p className="info-sub manu-text clickable" onClick={() => { navigate("/safety-security") }}>Create safer cities with unified solutions for public security and protection.</p>
                        <p className="info-sub retail-text clickable" onClick={() => { navigate("/green-sustainable") }}>Transform cities into sustainable, resource-efficient, energy-optimized havens for a greener future.</p>
                        <p className="info-sub dc-text clickable" onClick={() => { navigate("/urban-mobility") }}>Streamline traffic, enforce regulations, and optimize transportation systems.</p>

                        <img className='icon1 ioc-icon clickable' src={ioc} onClick={() => { navigate("/UOC"); }}></img>
                        <img className='icon manu-icon clickable' src={manu} onClick={() => { navigate("/safety-security") }}></img>
                        <img className='icon retail-icon clickable' src={retail} onClick={() => { navigate("/green-sustainable") }}></img>
                        <img className='icon dc-icon clickable' src={dc} onClick={() => { navigate("/urban-mobility") }}></img>

                        <button className='nav-button-ec clickable' onClick={() => { setGlobalState("MfToEdgeCity", false); navigate("/") }}>{navbuttontext}</button>
                        <div className='dc-head'>digital cities</div>
                        <div className='dc-sub'>Technology is powering transformation in urban environment around the world</div>
                    </motion.div>
                </Delayed>
            </div>
        </>
    )
}

export default DigitalCityScreen;