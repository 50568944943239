import React, { useEffect, useState } from 'react';
import '../css/Navbar.css';
import dell from '../assets/dellLogo.png';
import intel from '../assets/intelLogo.png';
import linkedin from '../assets/linLogo.png';
import tweet from '../assets/twtLogo.png';
import { setGlobalState, useGlobalState } from '../utils/state';
import { Howl, Howler } from 'howler';
import audioPlayingIcon from '../assets/audioPlayingIcon.svg';
import audioMutedIcon from '../assets/audioMutedIcon.svg';
import audioPlayIcon from '../assets/playIcon.svg';
import UcaudioPlayIcon from '../assets/Group 32 (1).png';
import audioPauseIcon from '../assets/pauseIcon.svg';
import homeIcon from '../assets/Group 33.png';
import reset from '../assets/reset2.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { packageApp } from '../assets/assetsLocation';


function Navbar() {

  let navigate = useNavigate();
  let location = useLocation();


  const [muted, setMuted] = useState(false);
  const [play, setPlay] = useState(false);
  const [ApplicationDB, setApplicationType] = useGlobalState("ApplicationDB");
  const [playAndPause, setPlayAndPause] = useGlobalState("playAndPause");
  const [isTourOpen, setIsTourOpen] = useGlobalState("IsTourOpen");

  const playPause = () => {

    if (playAndPause) {
      setGlobalState("playAndPause", false);
      setPlay(false);
    }
    else {
      setGlobalState("playAndPause", true);
      setPlay(true);

    }
  }
  useEffect(() => {
    if (isTourOpen) {
      document.getElementById('playPauseButton').style.pointerEvents = 'none';
    } else {
      document.getElementById('playPauseButton').style.pointerEvents = 'auto';
    }
  }, [isTourOpen])
  const muteUnmute = () => {
    if (muted) {
      Howler.mute(false);
      setMuted(false);
      setGlobalState("IsMuted", false);

      try {
        var vid = document.getElementById("um-video");
        vid.muted = false;
      } catch (e) {
        return;
      }
      document.getElementById("muteButton").src = audioPlayingIcon;
    }
    else {
      Howler.mute(true);
      setMuted(true);
      setGlobalState("IsMuted", true);

      try {
        var vid = document.getElementById("um-video");
        vid.muted = true;
      } catch (e) {
        return;
      }
      document.getElementById("muteButton").src = audioMutedIcon;
    }
  }

  const handleHome = () => {
    Howler.stop();
    setGlobalState("MfToEdgeCity", false);
    navigate('/');
    window.location.reload();
  }

  const handleTwitterClick = () => {
    const text = encodeURIComponent("Just visited this amazing and innovative Edge! Click here to experience Dell Technologies' vision of the City of the Future. \n#DellTechnologies #Edge #DigitalCities");
    const url = "http://dell-edge.storyproc.com/";
    const twitterUrl = `https://twitter.com/intent/tweet?text=${text}&url=${url}`;
    window.open(twitterUrl, '_blank', 'width=600,height=400');
  };

  const handleLinkedInClick = () => {
    const encodedUrl = encodeURIComponent('http://dell-edge.storyproc.com/');
    const encodedTitle = encodeURIComponent('Edge Virtual City');
    const encodedSummary = encodeURIComponent("Just visited this amazing and innovative Edge! Click here to experience Dell Technologies' vision of the City of the Future. \n#DellTechnologies #Edge #DigitalCities");

    const linkedInUrl = `https://www.linkedin.com/shareArticle?mini=true&title=${encodedTitle}&summary=${encodedSummary}&url=${encodedUrl}`;

    window.open(linkedInUrl, '_blank', 'width=600,height=400');

  };
  const handleReset = () => {
    document.getElementById('reset').click()
  }
  return (
    <div className='nav-wrapper'>
      <div className='navbar'></div>
      <img className='dell' style={{ cursor: 'pointer' }} src={dell} onClick={() => handleReset()}></img>
      {<img className='twt withclick' src={tweet} onClick={handleTwitterClick}></img>}
      {<img className='lin withclick' src={linkedin} onClick={handleLinkedInClick}></img>}
      {<div className='divider2'></div>}
      {/* {ApplicationDB == "M" ?<img style={{left:'82.84%'}} id="homeButton" className='mute withclick' src={homeIcon} onClick={()=>handleHome()}></img>:''} */}
      <img style={{ left: '78.2775%' }} id="resetButton" className='mute withclick' src={reset} onClick={() => handleReset()}></img>
      <img style={{ left: '80.1475%' }} id="homeButton" className='mute withclick' src={homeIcon} onClick={() => handleHome()}></img>
      {<div className='divider3'></div>}
      <img style={{ left: '84.71%' }} id="playPauseButton" className='mute withclick' src={!playAndPause ? audioPlayIcon : isTourOpen ? UcaudioPlayIcon : audioPauseIcon} onClick={playPause}></img>
      <img id="muteButton" className='mute withclick' src={muted ? audioMutedIcon : audioPlayingIcon} onClick={muteUnmute}></img>
      {/* <img id="playButton" className='playPause withclick' src={playIcon} onClick={playPauseMusic}></img> */}
    </div>
  );
}

export default Navbar;