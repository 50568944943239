import { gsap } from "gsap";
import { Vector3 } from "@babylonjs/core";

const spiralAnimation = (scene, target, startPosition, endPosition, steps = 1000, animationTime = 1, func = () => {}, ...params) => {
	const r1 = Math.sqrt((target.x - startPosition.x)*(target.x - startPosition.x) + (target.z - startPosition.z)*(target.z - startPosition.z));
	const r2 = Math.sqrt((target.x - endPosition.x)*(target.x - endPosition.x) + (target.z - endPosition.z)*(target.z - endPosition.z));
	
	var a1 = Math.atan2(startPosition.z - target.z, startPosition.x - target.x);
	var a2 = Math.atan2(endPosition.z - target.z, endPosition.x - target.x);

	if(a2 - a1 > Math.PI) {
		a2 = a2 - 2*Math.PI;
	}
	if(a2 - a1 < -Math.PI) {
		a2 = a2 + 2*Math.PI;
	}

	const cam = scene.getCameraByName('camera-3');
	scene.activeCamera = cam;
	cam.lockedTarget = target;
	cam.position.copyFrom(startPosition);
	const timeline = gsap.timeline();
	for(var i = 1; i <= steps; i++) {
		const r = r1 + i*(r2-r1)/steps;
		const a = a1 + i*(a2-a1)/steps;
		timeline.to(cam.position, {
			x: target.x + r * Math.cos(a),
			y: startPosition.y + (endPosition.y - startPosition.y)*i/steps,
			z: target.z + r * Math.sin(a),
			duration: (animationTime/steps)*(i === steps ? 1 : 1 - Math.pow(2, -10 * (i/steps))),
		});
	}
	timeline.to(cam.position, {
		x: target.x + r2 * Math.cos(a2),
		y: endPosition.y,
		z: target.z + r2 * Math.sin(a2),
		duration: (animationTime/steps),
		onComplete: () => {
			func(...params);
		}
	});
	timeline.play();
}

const linearAnimation = (scene, target, startPosition, endPosition, animationTime = 1, func = () => {}, ...params) => {
	const cam = scene.getCameraByName('camera-3');
	scene.activeCamera = cam;
	cam.lockedTarget = target;
	cam.position.copyFrom(startPosition);
	const timeline = gsap.timeline();
	timeline.to(cam.position, {
		x: endPosition.x,
		y: endPosition.y,
		z: endPosition.z,
		duration: animationTime,
		ease: "power2.out",
		onComplete: () => {
			func(...params);
		}
	});
	timeline.play();
}

const rotateToTarget = (scene, target, cam, animationTime = 0.4, func = () => {}, ...params) => {
	scene.activeCamera = cam;
	cam.lockedTarget = null;
	let direction = Vector3.Normalize(new Vector3(target.x - cam.position.x, target.y - cam.position.y, target.z - cam.position.z));
	let projectedDirection = Vector3.Normalize(new Vector3(target.x - cam.position.x, 0, target.z - cam.position.z));
	let dotProduct = Vector3.Dot(direction, projectedDirection);
	let beta = Math.acos(dotProduct);
	let alpha = Math.atan2(direction.x, direction.z);
	let rotation = alpha - cam.rotation.y;
	if (Math.abs(rotation) > Math.abs(rotation - 2*Math.PI)) rotation = rotation - 2*Math.PI;
	if (Math.abs(rotation) > Math.abs(rotation + 2*Math.PI)) rotation = rotation + 2*Math.PI;
	alpha = rotation + cam.rotation.y;

	if(cam.rotation.x === beta && cam.rotation.y === alpha) {
		animationTime = 0;
	}

	const timeline = gsap.timeline();
	timeline.to(cam.rotation, {
		x: beta,
		y: alpha,
		duration: animationTime,
		ease: "power2.out",
		onComplete: () => {
			func(...params);
		}
	});
	timeline.play();
}

export { spiralAnimation, linearAnimation, rotateToTarget };