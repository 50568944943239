import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../css/ReactResponsiveCarousel.css";
import { setGlobalState, useGlobalState } from "../state";
import { ApplicationDB, assetsLocation, packageApp, TVScreenSolutions } from "../../assets/assetsLocation";

const Card = ({ item, setCurrentItem }) => { // Added setCurrentItem prop
  const [applicationDB] = useGlobalState("ApplicationDB");
  const [isImageFullScreen, setIsImageFullScreen] = useGlobalState("isImageFullScreen");

  // const toggleFullscreen = () => { // Updated toggleFullscreen to use setCurrentItem
  //   setIsImageFullScreen(!isImageFullScreen);
  //   setCurrentItem(item); // Set current item when toggling fullscreen
  // };

  const isImage = (url) => {
    return /\.(jpg|JPG|jpeg|png|webp|avif|gif|svg)$/.test(url);
  };

  return (
    <div className={TVScreenSolutions ? "CardContainer" : ""}>
      {isImage(item.graphic) ? (
        <div>
          <img
            alt="test"
            src={!packageApp ? `${assetsLocation}${ApplicationDB}/graphics/${item.graphic}` : `../../${ApplicationDB}/graphics/${item.graphic}`}
          />
          {/* <button onClick={toggleFullscreen} className="fullscreen-button">
            {isImageFullScreen ? (
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_93_31" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
                  <rect x="0.678864" y="0.910553" width="24" height="24" fill="#005486" />
                </mask>
                <g mask="url(#mask0_93_31)">
                  <path d="M6.67886 21.9106V18.9106H3.67886V16.9106H8.67886V21.9106H6.67886ZM16.6789 21.9106V16.9106H21.6789V18.9106H18.6789V21.9106H16.6789ZM3.67886 8.91055V6.91055H6.67886V3.91055H8.67886V8.91055H3.67886ZM16.6789 8.91055V3.91055H18.6789V6.91055H21.6789V8.91055H16.6789Z" fill="#005486" />
                </g>
              </svg>
            ) : (
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_93_13" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                  <rect width="24" height="24" fill="#005486" />
                </mask>
                <g mask="url(#mask0_93_13)">
                  <path d="M3 21V16H5V19H8V21H3ZM16 21V19H19V16H21V21H16ZM3 8V3H8V5H5V8H3ZM19 8V5H16V3H21V8H19Z" fill="#005486" />
                </g>
              </svg>
            )}
          </button> */}
        </div>
      ) : (
        <div>
          <video
            autoPlay={!isImage(item.graphic)}
            preload="auto"
            id="mf-video"
            muted
            controls
            style={{ width: "100%", verticalAlign: "bottom" }}
          >
            <source
              src={!packageApp ? `${assetsLocation}${ApplicationDB}/graphics/${item.graphic}` : `../../${ApplicationDB}/graphics/${item.graphic}`}
              type="video/mp4"
            />
          </video>
        </div>
      )}
    </div>
  );
};

const ReactResponsiveCarousel = ({ solutionGraphicsData }) => {
  const [isAutoPlay] = useGlobalState("IsAutoPlay");
  const [applicationDB] = useGlobalState("ApplicationDB");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [imagePosition, setImagePosition] = useState(0);
  const [isImageFullScreen, setIsImageFullScreen] = useGlobalState("isImageFullScreen");
  const [currentItem, setCurrentItem] = useState(null); // Added state to track current item

  document.onkeydown = function (evt) {
    if (evt.keyCode === 27) {
      setIsFullScreen(false);
    }
  };

  // const toggleFullscreen = () => {
  //   setIsImageFullScreen(!isImageFullScreen);
  // };


  const isGraphicSame = solutionGraphicsData.every(item => item.graphic === solutionGraphicsData[0].graphic);

  return (
    <>
      {isFullScreen && currentItem && (
        <img
          className="full-screen-img"
          src={!packageApp ? `${assetsLocation}${applicationDB}/graphics/${currentItem.graphic}` : `../../${applicationDB}/graphics/${currentItem.graphic}`}
        />
      )}
      {isImageFullScreen && currentItem && (
        <div className="background">
          <div className="fullscreen">
            <img
              alt="test"
              src={!packageApp ? `${assetsLocation}${applicationDB}/graphics/${currentItem.graphic}` : `../../${applicationDB}/graphics/${currentItem.graphic}`}
            />
          </div>
        </div>
      )}
      <div className={TVScreenSolutions ? "CarouselContainer" : ""}>
        {isGraphicSame ? (
          <Card item={solutionGraphicsData[0]} setCurrentItem={setCurrentItem} />
        ) : (
          <Carousel
            width="auto"
            dynamicHeight={false}
            autoPlay={isAutoPlay}
            stopOnHover
            swipeable
            infiniteLoop
            showIndicators={false}
            emulateTouch
            showArrows={false}
            showThumbs={false}
            showStatus={false}
            selectedItem={0}
            interval={2000}
            onChange={(e) => setImagePosition(e)}
          >
            {solutionGraphicsData.map((item, index) => (
              <Card key={item.order_seq} item={item} setCurrentItem={setCurrentItem} />
            ))}
          </Carousel>
        )}
      </div>
    </>
  );
};

export default ReactResponsiveCarousel;
