import React, { useMemo, useEffect, useRef, useState } from "react";
import { setGlobalState, useGlobalState } from "../state";

import "../css/cardcontainer.css";

function Card(props) {
  const [transitionValue, setTransitionValue] = useState();
  const [transformValue, setTransformValue] = useState();
  const panelValue = useRef();
  const [highlightCurrentCard, sethighlightCurrentCard] = useGlobalState("highlightCurrentCard");

  let mouseX, mouseY;
  let transformAmount = 20;

  const transformPanel = (e) => {
    mouseX = e.pageX;
    mouseY = e.pageY;

    const centerX =
      panelValue.current.offsetLeft + panelValue.current.clientWidth / 2;
    const centerY =
      panelValue.current.offsetTop + panelValue.current.clientHeight / 2;

    const percentX = (centerX - mouseX) / (panelValue.current.clientWidth / 2);
    const percentY = -(
      (centerY - mouseY) /
      (panelValue.current.clientHeight / 2)
    );

    setTransformValue(
      "perspective(1000px) rotateY(" +
        percentX * transformAmount +
        "deg) rotateX(" +
        percentY * transformAmount +
        "deg) scale3d(1.1,1.1,1.1)"
    );
  };

  const handleMouseEnter = () => {
    setTimeout(() => {
      setTransitionValue("");
    }, 100);
    setTransitionValue("transform 0.1s");
  };

  const handleMouseLeave = () => {
    setTransitionValue("transform 0.1s scale3d(1,1,1)");

    setTimeout(() => {
      setTransitionValue("");
    }, 100);
    setTransformValue(
      "perspective(1000px) rotateY(0deg) rotateX(0deg) scale3d(1,1,1)"
    );
  };
  const [currentPage, setCurrentPage] = useState(0);

  const indexArr = [1, 4];

	useEffect(() => {
    const textElements = document.querySelectorAll('.card-content');
    const calculateFontSize = (element) => {
			const fontSize = parseFloat(getComputedStyle(element).fontSize);

			if (fontSize < 9) {
        element.style.display = 'none';
      } else {
        element.style.display = 'block';
      }
    };

    textElements.forEach((element) => {
      calculateFontSize(element);
    });

    const handleResize = () => {
      textElements.forEach((element) => {
        calculateFontSize(element);
      });
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    console.log(highlightCurrentCard, props.id);
  }, [highlightCurrentCard]);

  return (
    <div id="container">
      <div
        ref={panelValue}
        id="panel"
        onMouseMove={(e) => transformPanel(e)}
        onMouseEnter={() => handleMouseEnter()}
        onMouseLeave={() => handleMouseLeave()}
      >
				<div
					id="panel-container"
					style={{ transition: transitionValue, transform: transformValue,boxShadow:"-30px 30px 35px -10px #00000066" }}
				>
					<div
						className={`card ${
							indexArr.includes(props.index) ? props.alignItems ? "" : "extra-margin-card" : ""
						} ${(highlightCurrentCard == props.id) ? "current-card" : ""}`}
					>
						<h2 className="card-heading">{props.heading}</h2>
						<hr className="card-divider" />
						<p className="card-content">{props.content}</p>
					</div>
				</div>
      </div>
    </div>
  );
}

export default Card;
