import React, { useState } from "react";
import CardContainer from "./CardContainer";
import PopupToolbar from "./PopupToolbar";
import "../css/cardcontainer.css";
import "../css/popuptoolbar.css";
import UseCasePopupToolbar from "./UseCasePopupToolbar";
import CascadingMenuFA from "./CascadingMenuFA.jsx";
import CascadingMenuFAUC from "./CascadingMenuFAUC.jsx";
import CascadingMenuIOC from "./CascadingMenuIOC.jsx";
import Welcome from "./Welcome.jsx";
import { TrackGoogleAnalyticsEvent, InitializeGoogleAnalytics } from "./googleanalytics.tsx";
import { useEffect } from "react";
import { ApplicationDB } from "../../assets/assetsLocation.js";

function MenuDispensor(props) {
	useEffect(() => {
		InitializeGoogleAnalytics();
		TrackGoogleAnalyticsEvent(props.ui_element, ApplicationDB, props.buttonId);
	}, [props.buttonId]);

	return (
		<div>
			{props.ui_element === "cards" && (
				<div className="cardcontainer">
					<CardContainer
						sectionData={props.sectionData}
						useCaseMapping={props.useCaseMapping}
						alignItems={props.alignItems}
						handlePlayStory={props.handlePlayStory}
						showCardContainer={props.showCardContainer}
						link_type={props.link_type}
					/>
				</div>
			)}
			{props.ui_element === "welcome" && (
				<Welcome WelcomeData={props.WelcomeData} WelcomeData1={props.WelcomeData1} count={props.count} handleSkip={props.handleSkip} handleNext={props.handleNext} />
			)}
			{props.ui_element === "cascading" && props.buttonType == "Use_case" &&
				<div className="popuptoolbar">
					<CascadingMenuFAUC
						buttonType={props.buttonType}
						sectionData={props.sectionData}
						buttonId={props.buttonId}
						anchorEl={props.anchorEl}
						handleClose={props.handleClose}
						handleMenuItemClick={props.handleMenuItemClick}
						open={props.open}
						showCardContainer={props.showCardContainer}
						handleCascadingButtonClick={props.handleCascadingButtonClick}
					/>
				</div>
			}
			{props.ui_element === "cascading" && props.buttonType != "Use_case" &&
				<div className="popuptoolbar">
					<CascadingMenuFA
						buttonType={props.buttonType}
						sectionData={props.sectionData}
						buttonId={props.buttonId}
						anchorEl={props.anchorEl}
						handleClose={props.handleClose}
						handleMenuItemClick={props.handleMenuItemClick}
						open={props.open}
						showCardContainer={props.showCardContainer}
						handleCascadingButtonClick={props.handleCascadingButtonClick}
					/>
				</div>
			}
			{props.ui_element === "cascadingioc" &&
				<div className="popuptoolbar">
					<CascadingMenuIOC
						buttonType={props.buttonType}
						sectionData={props.sectionData}
						buttonId={props.buttonId}
						anchorEl={props.anchorEl}
						handleClose={props.handleClose}
						handleMenuItemClick={props.handleMenuItemClick}
						open={props.open}
						showCardContainer={props.showCardContainer}
						handleCascadingButtonClick={props.handleCascadingButtonClick}
						ApplicationDB={props.ApplicationDB}
						IsGuidedTourOpen={props.IsGuidedTourOpen}
					/>
				</div>
			}
			{props.ui_element === "popuptoolbar" && (
				<div className="popuptoolbar">
					{props.buttonType == "Use_case" ? (
						<UseCasePopupToolbar
							buttonType={props.buttonType}
							sectionData={props.sectionData}
							anchorEl={props.anchorEl}
							handleClose={props.handleClose}
							handleMenuItemClick={props.handleMenuItemClick}
							open={props.open}
							showCardContainer={props.showCardContainer}
							ApplicationDB={props.ApplicationDB}
							IsGuidedTourOpen={props.IsGuidedTourOpen}
						/>
					) : (
						<PopupToolbar
							buttonType={props.buttonType}
							sectionData={props.sectionData}
							buttonId={props.buttonId}
							anchorEl={props.anchorEl}
							handleClose={props.handleClose}
							handleMenuItemClick={props.handleMenuItemClick}
							open={props.open}
							showCardContainer={props.showCardContainer}
						/>
					)}
				</div>
			)}
		</div>
	);
}

export default MenuDispensor;
